import { Box } from '@mui/material';
import {
  BarChart2,
  DollarSign,
  Eye,
  MousePointerClick,
  Percent,
  ScrollText,
  ShoppingCart,
} from 'lucide-react';
import React from 'react';

type IconType =
  | 'eye'
  | 'scroll'
  | 'barchart'
  | 'click'
  | 'cart'
  | 'percent'
  | 'dollar';

export const getColumnIcon = (type: IconType) => {
  switch (type) {
    case 'eye':
      return <Eye size={16} />;
    case 'scroll':
      return <ScrollText size={16} />;
    case 'barchart':
      return <BarChart2 size={16} />;
    case 'click':
      return <MousePointerClick size={16} />;
    case 'cart':
      return <ShoppingCart size={16} />;
    case 'percent':
      return <Percent size={16} />;
    case 'dollar':
      return <DollarSign size={16} />;
  }
};

export const getTooltipTitle = (type: IconType, title: string) => {
  const icon = React.cloneElement(getColumnIcon(type), { size: 18 });
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {icon}
      {title}
    </Box>
  );
};
