import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ComponentMetrics } from '../../../services/component-service';
import {
  DailySSMetrics,
  DailyShelfMetrics,
} from '../../../services/shelf-service';
import IXSummaryTable from '../ix-summary-table/IXSummaryTable';
import MetricsSummaryCards from '../ix-summary-table/MetricsSummaryCards';
import OverallMetricsCharts from '../ix-summary-table/OverallMetricsCharts';
import PageTypeMetricsCharts from '../ix-summary-table/PageTypeMetricsCharts';
import TimeUnitSelector, {
  TimeUnit,
} from '../ix-summary-table/TimeUnitSelector';

interface IXSummarySectionProps {
  metrics: ComponentMetrics[];
  dailyMetrics: DailyShelfMetrics[];
  dailySSMetrics: DailySSMetrics[];
  startDate: string;
  endDate: string;
  unitPrice: number;
}

const IXSummarySection: React.FC<IXSummarySectionProps> = ({
  metrics,
  dailyMetrics,
  dailySSMetrics,
  startDate,
  endDate,
  unitPrice,
}) => {
  const [timeUnit, setTimeUnit] = useState<TimeUnit>('day');

  return (
    <Box
      sx={{
        mb: 6,
        bgcolor: 'background.paper',
        borderRadius: 3,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          p: 3,
          bgcolor: 'primary.main',
          color: 'white',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          InsightXコンポーネント サマリー
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5, opacity: 0.9 }}>
          シェルフの全体およびページタイプ別のパフォーマンス指標
        </Typography>
      </Box>
      <Box sx={{ p: 3 }}>
        <MetricsSummaryCards data={dailySSMetrics} unitPrice={unitPrice} />
        <Box sx={{ mb: 3 }}>
          <TimeUnitSelector value={timeUnit} onChange={setTimeUnit} />
        </Box>
        <OverallMetricsCharts
          data={dailySSMetrics}
          timeUnit={timeUnit}
          unitPrice={unitPrice}
        />
        <PageTypeMetricsCharts data={dailyMetrics} timeUnit={timeUnit} />
        <IXSummaryTable
          metrics={metrics}
          startDate={startDate}
          endDate={endDate}
          unitPrice={unitPrice}
        />
      </Box>
    </Box>
  );
};

export default IXSummarySection;
