import { Box, Card, CardContent, Typography } from '@mui/material';
import { format, parseISO, startOfMonth, startOfWeek } from 'date-fns';
import { ja } from 'date-fns/locale';
import { Calculator, Lightbulb, Users } from 'lucide-react';
import React, { useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DailySSMetrics } from '../../../services/shelf-service';

interface OverallMetricsChartsProps {
  data: DailySSMetrics[];
  timeUnit: string;
  unitPrice: number;
}

interface ChartData {
  date: string;
  ss: number;
  shelf_shown: number;
  shelf_click: number;
  revenue: number;
  shelf_revenue: number;
}

const chartDefinitions = [
  {
    id: 'sessions',
    title: 'セッション数の推移',
    color: '#9C27B0',
    description: 'ユーザー行動の各段階でのセッション数を追跡',
    metrics: [
      {
        name: '全体SS',
        description: 'サイト訪問の総セッション数',
        color: '#9C27B0',
      },
      {
        name: 'シェルフ表示SS',
        description: 'シェルフが表示されたセッション数',
        color: '#4CAF50',
      },
      {
        name: 'シェルフクリックSS',
        description: 'シェルフがクリックされたセッション数',
        color: '#FF9800',
      },
    ],
    kpis: [
      {
        name: 'シェルフ表示率',
        calculation: 'シェルフ表示SS ÷ 全体SS',
        target: '',
        description:
          'サイト訪問者のうち、どれだけの割合がシェルフに触れているか',
      },
      {
        name: 'シェルフクリック率',
        calculation: 'シェルフクリックSS ÷ シェルフ表示SS',
        target: '目標：20%以上',
        description:
          'シェルフを見たユーザーのうち、どれだけが興味を持ってクリックしたか',
      },
    ],
    tips: [
      '表示率が低い場合は、シェルフの設置位置や表示ページの見直しを検討',
      'クリック率が低い場合は、シェルフの選定やデザインの改善を検討',
    ],
  },
  {
    id: 'revenue',
    title: '売上（百万円）の推移',
    color: '#2196F3',
    description: 'レコメンド経由の売上貢献度を可視化',
    metrics: [
      {
        name: '全体売上',
        description: 'サイト全体での売上金額',
        color: '#2196F3',
      },
      {
        name: 'シェルフ経由売上',
        description: 'レコメンドを経由した売上金額',
        color: '#F44336',
      },
    ],
    kpis: [
      {
        name: 'シェルフ経由売上比率',
        calculation: 'シェルフ経由売上 ÷ 全体売上',
        target: '',
        description: '全体売上のうち、レコメンド経由の売上が占める割合',
      },
    ],
    tips: [
      '売上比率が低い場合は、高単価商品のレコメンド比率を調整',
      'クリック当たり売上が低い場合は、類似価格帯の商品をレコメンド',
    ],
  },
];

const calculateKPIs = (data: ChartData[]) => {
  const totals = data.reduce(
    (acc, curr) => ({
      totalSS: acc.totalSS + curr.ss,
      shelfShownSS: acc.shelfShownSS + curr.shelf_shown,
      shelfClickSS: acc.shelfClickSS + curr.shelf_click,
      totalRevenue: acc.totalRevenue + curr.revenue,
      shelfRevenue: acc.shelfRevenue + curr.shelf_revenue,
    }),
    {
      totalSS: 0,
      shelfShownSS: 0,
      shelfClickSS: 0,
      totalRevenue: 0,
      shelfRevenue: 0,
    },
  );

  return {
    sessions: {
      shelfShownRate: totals.shelfShownSS / totals.totalSS,
      shelfClickRate: totals.shelfClickSS / totals.shelfShownSS,
    },
    revenue: {
      shelfRevenueRate: totals.shelfRevenue / totals.totalRevenue,
      revenuePerClick: totals.shelfRevenue / totals.shelfClickSS,
    },
  };
};

const formatPercent = (value: number) => `${(value * 100).toFixed(1)}%`;

const formatRevenue = (value: number) =>
  `¥${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;

const CustomTooltip = ({ active, payload, label, valueFormat }: any) => {
  if (!active || !payload) return null;

  return (
    <Box
      sx={{
        bgcolor: 'rgba(255, 255, 255, 0.95)',
        p: 2,
        borderRadius: 1,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
        border: '1px solid rgba(0, 0, 0, 0.05)',
      }}
    >
      <Typography
        variant="body2"
        sx={{ mb: 1, fontWeight: 'bold', color: 'text.primary' }}
      >
        {format(parseISO(label), 'yyyy/M/d (E)', { locale: ja })}
      </Typography>
      {payload.map((entry: any) => (
        <Box
          key={entry.dataKey}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 0.5,
            gap: 1,
          }}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              bgcolor: entry.color,
              borderRadius: '50%',
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              minWidth: 120,
            }}
          >
            {entry.name}:
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            {valueFormat(entry.value)}
          </Typography>
          {entry.percentage && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              ({entry.percentage}%)
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

const OverallMetricsCharts: React.FC<OverallMetricsChartsProps> = ({
  data,
  timeUnit,
  unitPrice,
}) => {
  const [hoveredChart, setHoveredChart] = useState<string | null>(null);

  const getTimeUnitStartDate = (date: string) => {
    const dateObj = parseISO(date);
    switch (timeUnit) {
      case 'week':
        return format(startOfWeek(dateObj, { locale: ja }), 'yyyy-MM-dd');
      case 'month':
        return format(startOfMonth(dateObj), 'yyyy-MM-dd');
      default:
        return date;
    }
  };

  // processData 関数内の revenue 計算部分を修正
  const processData = (metrics: DailySSMetrics[]): ChartData[] => {
    const groupedData = metrics.reduce(
      (acc, curr) => {
        const date = curr.ymd;
        const key = getTimeUnitStartDate(date);

        if (!acc[key]) {
          acc[key] = {
            date: key,
            ss: 0,
            shelf_shown: 0,
            shelf_click: 0,
            revenue: 0,
            shelf_revenue: 0,
          };
        }

        acc[key].ss += curr.ss;
        acc[key].shelf_shown += curr.shelf_shown;
        acc[key].shelf_click += curr.shelf_click;
        // 修正：単価を掛ける
        acc[key].revenue += (curr.cv * unitPrice) / 1000000;
        acc[key].shelf_revenue += (curr.shelf_cv * unitPrice) / 1000000;

        return acc;
      },
      {} as { [key: string]: ChartData },
    );

    return Object.values(groupedData).sort((a, b) =>
      a.date.localeCompare(b.date),
    );
  };

  const formatXAxisTick = (date: string) => {
    switch (timeUnit) {
      case 'week':
        return format(parseISO(date), 'M/d週', { locale: ja });
      case 'month':
        return format(parseISO(date), 'yyyy/M月', { locale: ja });
      default:
        return format(parseISO(date), 'M/d', { locale: ja });
    }
  };

  const chartData = processData(data);
  const kpis = calculateKPIs(chartData);

  return (
    <Box sx={{ mb: 6 }}>
      <Typography
        variant="h5"
        sx={{
          mb: 3,
          fontWeight: 'bold',
          color: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Users size={24} />
        全体パフォーマンス
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 4,
          position: 'relative',
          zIndex: 1,
        }}
      >
        {chartDefinitions.map((definition) => (
          <Box
            key={definition.id}
            sx={{
              position: 'relative',
              mb: hoveredChart === definition.id ? 16 : 0,
              transition: 'margin-bottom 0.3s ease',
            }}
          >
            <Box
              onMouseEnter={() => setHoveredChart(definition.id)}
              onMouseLeave={() => setHoveredChart(null)}
              sx={{
                position: 'relative',
                zIndex: hoveredChart === definition.id ? 2 : 1,
              }}
            >
              <Card
                sx={{
                  height: '100%',
                  borderRadius: 2,
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                  bgcolor: '#FFFFFF',
                  transition: 'all 0.3s ease',
                  transform:
                    hoveredChart === definition.id
                      ? 'translateY(-4px)'
                      : 'none',
                }}
              >
                <CardContent sx={{ p: 3 }}>
                  <Box sx={{ mb: 3 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          bgcolor: definition.color,
                        }}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '1.25rem',
                          color: definition.color,
                        }}
                      >
                        {definition.title}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary', pl: 4 }}
                    >
                      {definition.description}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100%', height: 360 }}>
                    <ResponsiveContainer>
                      <AreaChart
                        data={chartData}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 0,
                          bottom: 60,
                        }}
                      >
                        <CartesianGrid
                          strokeDasharray="3 3"
                          vertical={false}
                          stroke="rgba(0, 0, 0, 0.1)"
                        />
                        <XAxis
                          dataKey="date"
                          tickFormatter={formatXAxisTick}
                          tick={{ fontSize: 12, fill: '#666' }}
                          tickLine={{ stroke: '#ccc' }}
                          axisLine={{ stroke: '#ccc' }}
                        />
                        <YAxis
                          width={80}
                          tickFormatter={(value) =>
                            value.toLocaleString(undefined, {
                              maximumFractionDigits: 1,
                            })
                          }
                          tick={{ fontSize: 12, fill: '#666' }}
                          tickLine={{ stroke: '#ccc' }}
                          axisLine={{ stroke: '#ccc' }}
                        />
                        <Tooltip
                          content={(props) => (
                            <CustomTooltip
                              {...props}
                              valueFormat={(value: number) =>
                                definition.id === 'revenue'
                                  ? value.toLocaleString(undefined, {
                                      maximumFractionDigits: 1,
                                    }) + '百万円'
                                  : value.toLocaleString()
                              }
                            />
                          )}
                        />
                        <Legend />
                        {definition.metrics.map((metric) => (
                          <Area
                            key={metric.name}
                            type="monotone"
                            dataKey={
                              definition.id === 'sessions'
                                ? metric.name === '全体SS'
                                  ? 'ss'
                                  : metric.name === 'シェルフ表示SS'
                                    ? 'shelf_shown'
                                    : 'shelf_click'
                                : metric.name === '全体売上'
                                  ? 'revenue'
                                  : 'shelf_revenue'
                            }
                            name={metric.name}
                            stackId={metric.name}
                            stroke={metric.color}
                            fill={metric.color}
                            fillOpacity={0.3}
                          />
                        ))}
                      </AreaChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
              {hoveredChart === definition.id && (
                <Card
                  sx={{
                    p: 2,
                    backgroundColor: 'white',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                    borderRadius: 2,
                    border: `2px solid ${definition.color}`,
                    width: '100%',
                    mt: 2,
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gap: 2,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: 'bold',
                          mb: 1,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Calculator size={18} /> 重要指標（KPI）
                      </Typography>
                      {definition.kpis.map((kpi, index) => (
                        <Box key={index} sx={{ mb: 1 }}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 'bold' }}
                          >
                            {kpi.name}
                            {definition.id === 'sessions'
                              ? kpi.name === 'シェルフ表示率'
                                ? `: ${formatPercent(kpis.sessions.shelfShownRate)}`
                                : `: ${formatPercent(kpis.sessions.shelfClickRate)}`
                              : kpi.name === 'シェルフ経由売上比率'
                                ? `: ${formatPercent(kpis.revenue.shelfRevenueRate)}`
                                : `: ${formatRevenue(kpis.revenue.revenuePerClick)}`}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'text.secondary',
                              fontSize: '0.875rem',
                            }}
                          >
                            {kpi.description}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: 'bold',
                          mb: 1,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Lightbulb size={18} /> 改善のポイント
                      </Typography>
                      <Box component="ul" sx={{ m: 0, pl: 2 }}>
                        {definition.tips.map((tip, index) => (
                          <Typography
                            key={index}
                            component="li"
                            variant="body2"
                            sx={{ mb: 0.5 }}
                          >
                            {tip}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Card>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OverallMetricsCharts;
