import { Box, Grid, TextField, Typography, useTheme } from '@mui/material';
import { Calendar, Wallet } from 'lucide-react';
import React from 'react';
import DateRangePicker from './DateRangePicker';

interface MetricsHeaderProps {
  startDate: string;
  endDate: string;
  unitPrice: number;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
  onUnitPriceChange: (price: number) => void;
}

const MetricsHeader: React.FC<MetricsHeaderProps> = ({
  startDate,
  endDate,
  unitPrice,
  onStartDateChange,
  onEndDateChange,
  onUnitPriceChange,
}) => {
  const theme = useTheme();

  const handleUnitPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value.replace(/,/g, '')) || 0;
    onUnitPriceChange(value);
  };

  return (
    <Grid container spacing={3} alignItems="center" sx={{ mb: 3 }}>
      <Grid item xs={12} md={4}>
        <Typography
          sx={{
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            fontSize: '30px',
            lineHeight: 1,
          }}
        >
          InsightXシェルフ指標
        </Typography>
      </Grid>
      <Grid item container xs={12} md={8} spacing={3}>
        <Grid item xs={12} md={6}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <Calendar size={20} color={theme.palette.primary.main} />
              <Typography
                variant="subtitle2"
                sx={{ color: theme.palette.text.secondary }}
              >
                集計期間
              </Typography>
            </Box>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={onStartDateChange}
              onEndDateChange={onEndDateChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <Wallet size={20} color={theme.palette.primary.main} />
              <Typography
                variant="subtitle2"
                sx={{ color: theme.palette.text.secondary }}
              >
                CVセッション単価
              </Typography>
            </Box>
            <TextField
              type="text"
              value={unitPrice.toLocaleString()}
              onChange={handleUnitPriceChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: '1rem',
                      pl: 1,
                    }}
                  >
                    円
                  </Typography>
                ),
                sx: {
                  '& input': {
                    textAlign: 'right',
                    pr: 1,
                    fontSize: '1rem',
                  },
                  height: '42px',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#fff',
                },
              }}
            />
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.text.secondary,
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                mt: 0.5,
              }}
            >
              ※ 指定期間の平均CV単価を入力ください
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MetricsHeader;
