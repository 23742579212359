import { Box, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { columns } from './constants';
import { MetricsRowProps } from './types';
import { calculateMonthlyRevenue, safeNumber } from './utils';

const STICKY_COLUMN_STYLE = {
  position: 'sticky',
  left: 0,
  zIndex: 2,
  bgcolor: 'grey.50',
  borderRight: '1px solid',
  borderRightColor: 'divider',
  minWidth: '300px',
  width: '300px',
} as const;

export const MetricsRow: React.FC<MetricsRowProps> = React.memo(
  ({
    metric,
    maxValues,
    startDate,
    endDate,
    unitPrice,
    hoveredColumn,
    onColumnHover,
    onColumnLeave,
  }) => {
    const getColumnStyle = (columnId: string) => ({
      position: 'relative',
      textAlign: 'right' as const,
      py: 1.5,
      px: 2,
      whiteSpace: 'nowrap' as const,
      width: columns.find((col) => col.id === columnId)?.width,
      minWidth: columns.find((col) => col.id === columnId)?.width,
      bgcolor: 'grey.50',
      cursor: 'pointer',
      '&:hover': {
        '& .metrics-bar': {
          opacity: 0.15,
        },
        '& .metrics-value': {
          color: 'primary.main',
        },
      },
    });

    return (
      <TableRow
        sx={{
          '&:hover': { bgcolor: 'action.hover' },
          bgcolor: 'grey.50',
        }}
      >
        <TableCell sx={{ ...STICKY_COLUMN_STYLE, pl: 6 }}>
          <Typography variant="body2">
            {metric.gray_tagline} {metric.bold_tagline}
          </Typography>
        </TableCell>
        {columns.map((column) => {
          const value =
            column.id === 'revenue'
              ? calculateMonthlyRevenue(
                  safeNumber(metric.cv),
                  unitPrice,
                  startDate,
                  endDate,
                )
              : safeNumber(metric[column.id]);

          const percentage =
            maxValues[column.id] > 0 ? (value / maxValues[column.id]) * 100 : 0;
          const limitedPercentage = Math.min(percentage, 99);

          return (
            <TableCell
              key={column.id}
              sx={{
                ...getColumnStyle(column.id),
                '& .metrics-value': {
                  color:
                    hoveredColumn === column.id
                      ? 'primary.main'
                      : 'text.primary',
                  fontWeight: hoveredColumn === column.id ? 'bold' : 'medium',
                },
                '& .metrics-bar': {
                  opacity: hoveredColumn === column.id ? 0.3 : 0.1,
                },
              }}
              onMouseEnter={() => onColumnHover(column.id)}
              onMouseLeave={onColumnLeave}
            >
              <Box
                className="metrics-bar"
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  width: `${limitedPercentage}%`,
                  bgcolor: 'primary.main',
                  transition: 'opacity 0.2s',
                }}
              />
              <Typography
                variant="body2"
                className="metrics-value"
                sx={{
                  position: 'relative',
                  transition: 'color 0.2s, font-weight 0.2s',
                }}
              >
                {column.format(value)}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  },
);

MetricsRow.displayName = 'MetricsRow';

export default MetricsRow;
