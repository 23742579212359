import { Button, TextField } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  background-color: #f7f9fc;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  background-color: #ffffff;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const AddButton = styled(Button)`
  background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
  color: white;
`;

export const DeleteButton = styled(Button)`
  margin-left: 16px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export const ShelfContainer = styled.div<{ $isDeleted: boolean }>`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: ${(props) => (props.$isDeleted ? 0.5 : 1)};
  ${(props) => props.$isDeleted && `text-decoration: line-through;`}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const StyledTextField = styled(TextField)<{ $isEmpty?: boolean }>`
  width: 100%;
  ${(props) =>
    props.$isEmpty &&
    `
    & .MuiInputBase-root {
      height: 40px;
    }
  `}
`;

export const LoadMoreButton = styled(Button)`
  margin-top: 16px;
`;
