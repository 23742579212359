import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shelfService } from '../../../services/shelf-service';
import CustomPageTabs from '../CustomPageTabs';
import { MetricTooltip } from '../ix-summary-table/MetricTooltip';
import { getColumnIcon, getTooltipTitle } from './column-components';
import { columns } from './constants';
import { ShelfTypeGroup } from './ShelfTypeGroup';
import { ShelfPerformanceProps } from './types';
import { aggregateMetricsByGroups, safeNumber } from './utils';

const ShelfPerformance: React.FC<ShelfPerformanceProps> = ({
  startDate,
  endDate,
  unitPrice,
  selectedPageType,
  onPageTypeChange,
}) => {
  const [expandedTypes, setExpandedTypes] = useState<Set<string>>(new Set());
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const columnHeaderRefs = useRef<Record<string, DOMRect>>({});

  const { data, isLoading, error } = useQuery({
    queryKey: ['shelfStats', startDate, endDate],
    queryFn: () =>
      shelfService.getShelfStats({
        start_date: startDate,
        end_date: endDate,
      }),
  });

  const updateColumnHeaderPosition = useCallback(
    (columnId: string, element: HTMLTableCellElement) => {
      const rect = element.getBoundingClientRect();
      columnHeaderRefs.current[columnId] = rect;
    },
    [],
  );

  const handleColumnHover = useCallback((columnId: string) => {
    const rect = columnHeaderRefs.current[columnId];
    if (rect) {
      setHoveredColumn(columnId);
      setTooltipPosition({
        top: rect.top,
        left: rect.left + rect.width / 2,
      });
    }
  }, []);

  const handleColumnLeave = useCallback(() => {
    setHoveredColumn(null);
    setTooltipPosition(null);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      columns.forEach((column) => {
        const element = document.querySelector(
          `[data-column-id="${column.id}"]`,
        ) as HTMLTableCellElement;
        if (element) {
          updateColumnHeaderPosition(column.id, element);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [updateColumnHeaderPosition]);

  const { pageTypes, shelfTypeGroups, maxValues } = useMemo(() => {
    if (!data) {
      return {
        pageTypes: [],
        shelfTypeGroups: new Map(),
        maxValues: Object.fromEntries(columns.map((col) => [col.id, 0])),
      };
    }

    let metrics = data.stats;

    if (selectedPageType === 'all') {
      metrics = aggregateMetricsByGroups(
        data.stats,
        unitPrice,
        startDate,
        endDate,
      );
    } else {
      metrics = data.stats.filter((m) => m.url_page_type === selectedPageType);
    }

    const types = [
      'all',
      ...Array.from(new Set(data.stats.map((m) => m.url_page_type))),
    ];

    const groups = new Map<string, typeof metrics>();
    metrics
      .sort((a, b) => safeNumber(b.shown) - safeNumber(a.shown))
      .forEach((metric) => {
        const key = metric.shelf_type;
        if (!groups.has(key)) {
          groups.set(key, []);
        }
        groups.get(key)!.push(metric);
      });

    groups.forEach((groupMetrics, key) => {
      groups.set(
        key,
        groupMetrics.sort((a, b) => safeNumber(b.shown) - safeNumber(a.shown)),
      );
    });

    const maxVals = columns.reduce(
      (acc, col) => {
        const values = metrics.map((m) => {
          if (col.id === 'revenue') {
            return safeNumber(m.revenue);
          }
          return safeNumber(m[col.id]);
        });
        acc[col.id] = Math.max(0, ...values);
        return acc;
      },
      {} as Record<string, number>,
    );

    const sortedGroups = new Map(
      Array.from(groups.entries()).sort((a, b) => {
        const aTotal = a[1].reduce((sum, m) => sum + safeNumber(m.shown), 0);
        const bTotal = b[1].reduce((sum, m) => sum + safeNumber(m.shown), 0);
        return bTotal - aTotal;
      }),
    );

    return {
      pageTypes: types,
      shelfTypeGroups: sortedGroups,
      maxValues: maxVals,
    };
  }, [data, selectedPageType, startDate, endDate]);

  const handleShelfTypeExpand = (shelfType: string) => {
    setExpandedTypes((prev) => {
      const next = new Set(prev);
      if (next.has(shelfType)) {
        next.delete(shelfType);
      } else {
        next.add(shelfType);
      }
      return next;
    });
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Typography color="error">
          データの読み込み中にエラーが発生しました
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <CustomPageTabs
          pageTypes={pageTypes}
          selectedPageType={selectedPageType}
          onChange={(_, value) => onPageTypeChange(value)}
          metrics={[]}
          startDate={startDate}
          endDate={endDate}
        />
      </Box>

      <TableContainer
        sx={{
          maxHeight: 600,
          overflow: 'auto',
          backgroundColor: 'white',
          borderRadius: 1,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  minWidth: 300,
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'white',
                  zIndex: 3,
                  borderRight: '1px solid',
                  borderRightColor: 'divider',
                }}
              >
                シェルフ種別 / タグライン
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  data-column-id={column.id}
                  ref={(el: HTMLTableCellElement | null) =>
                    el && updateColumnHeaderPosition(column.id, el)
                  }
                  align="right"
                  sx={{
                    minWidth: column.width,
                    whiteSpace: 'nowrap',
                    backgroundColor: 'white',
                    zIndex: 2,
                    cursor: 'pointer',
                    '&:hover': {
                      '& .column-header': {
                        color: 'primary.main',
                      },
                    },
                  }}
                  onMouseEnter={() => handleColumnHover(column.id)}
                  onMouseLeave={handleColumnLeave}
                >
                  <Box
                    className="column-header"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: 0.5,
                      transition: 'color 0.2s',
                    }}
                  >
                    {getColumnIcon(column.iconType)}
                    {column.label}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(shelfTypeGroups.entries()).map(
              ([shelfType, metrics]) => (
                <ShelfTypeGroup
                  key={shelfType}
                  shelfTypeKey={shelfType}
                  metrics={metrics}
                  maxValues={maxValues}
                  isExpanded={expandedTypes.has(shelfType)}
                  onExpand={handleShelfTypeExpand}
                  startDate={startDate}
                  endDate={endDate}
                  unitPrice={unitPrice}
                  hoveredColumn={hoveredColumn}
                  onColumnHover={handleColumnHover}
                  onColumnLeave={handleColumnLeave}
                />
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {hoveredColumn && tooltipPosition && (
        <Box
          sx={{
            position: 'fixed',
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            zIndex: 9999,
            transform: 'translate(-50%, -100%)',
            pointerEvents: 'none',
          }}
        >
          {(() => {
            const column = columns.find((col) => col.id === hoveredColumn);
            if (!column) return null;

            return (
              <MetricTooltip
                title={getTooltipTitle(column.iconType, column.tooltipTitle)}
                description={column.tooltipDescription}
                calculation={column.tooltipCalculation}
                insights={column.tooltipInsights}
              />
            );
          })()}
        </Box>
      )}
    </Box>
  );
};

export default ShelfPerformance;
