import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import {
  BarChart2,
  ChevronDown,
  ChevronRight,
  DollarSign,
  Eye,
  Heart,
  MousePointerClick,
  Percent,
  ShoppingCart,
} from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { ComponentMetrics } from '../../../services/component-service';
import {
  MetricTooltip,
  MetricTooltipProps,
} from '../ix-summary-table/MetricTooltip';

interface MetricsTableProps {
  metrics: ComponentMetrics[];
  startDate: string;
  endDate: string;
  unitPrice: number;
}

const safeNumber = (value: number | null | undefined): number => {
  if (value === null || value === undefined || isNaN(value)) {
    return 0;
  }
  return value;
};

const safeDivide = (numerator: number, denominator: number): number => {
  if (denominator === 0 || !denominator) return 0;
  const result = numerator / denominator;
  return isFinite(result) ? result : 0;
};

type ColumnDefinition = {
  id: string;
  label: string;
  icon: React.ReactNode;
  tooltip: Omit<MetricTooltipProps, 'title'> & {
    title: React.ReactNode;
  };
  format: (value: any) => string;
  getValue:
    | ((metric: any) => number)
    | ((
        metric: any,
        startDate: string,
        endDate: string,
        unitPrice: number,
      ) => number);
  width: string;
};

const columns: ColumnDefinition[] = [
  {
    id: 'shown',
    label: '表示数',
    icon: <Eye size={16} />,
    tooltip: {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Eye size={18} />
          表示数
        </Box>
      ),
      description: '期間中にユーザーの画面に表示された回数',
      calculation:
        'ページが<strong>表示</strong>され、<strong>コンポーネントが画面内に入った</strong>回数の合計',
      insights:
        'コンポーネントの<strong>リーチ</strong>を示す基本指標です。表示数が多いほど、より多くのユーザーにコンテンツが表示されていることを意味します',
    },
    format: (value: any) => safeNumber(value).toLocaleString(),
    getValue: (metric: any) => safeNumber(metric.shown),
    width: '140px',
  },
  {
    id: 'click',
    label: 'クリック数',
    icon: <MousePointerClick size={16} />,
    tooltip: {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <MousePointerClick size={18} />
          クリック数
        </Box>
      ),
      description: 'ユーザーがコンポーネントをクリックした回数',
      calculation:
        'コンポーネント内で商品が<strong>クリック</strong>された回数の合計',
      insights:
        'ユーザーの<strong>興味関心</strong>の度合いを示す重要指標です。コンポーネントの訴求力や関連性を評価する基準となります',
    },
    format: (value: any) => safeNumber(value).toLocaleString(),
    getValue: (metric: any) => safeNumber(metric.click),
    width: '120px',
  },
  {
    id: 'ctr',
    label: 'クリック率',
    icon: <BarChart2 size={16} />,
    tooltip: {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <BarChart2 size={18} />
          クリック率（CTR）
        </Box>
      ),
      description: '表示数に対するクリック数の割合',
      calculation: '<strong>クリック率</strong> = クリック数 ÷ 表示数',
      insights:
        'コンポーネントの<strong>魅力度</strong>と<strong>関連性</strong>を示す指標です。高いCTRは、ユーザーのニーズに合ったコンテンツを提供できていることを示唆します',
    },
    format: (value: any) => `${(safeNumber(value) * 100).toFixed(1)}%`,
    getValue: (metric: any) =>
      safeDivide(safeNumber(metric.click), safeNumber(metric.shown)),
    width: '100px',
  },
  {
    id: 'favorite',
    label: 'お気に入り数',
    icon: <Heart size={16} />,
    tooltip: {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Heart size={18} />
          お気に入り数
        </Box>
      ),
      description: 'ユーザーがお気に入りに追加した回数',
      calculation:
        'コンポーネントから商品が<strong>お気に入りに追加</strong>された回数の合計',
      insights:
        'ユーザーの<strong>長期的な興味</strong>を示す指標です。後で見返したい・購入を検討したい商品として認識されている度合いを表します',
    },
    format: (value: any) => safeNumber(value).toLocaleString(),
    getValue: (metric: any) => safeNumber(metric.favorite),
    width: '120px',
  },
  {
    id: 'favoriter',
    label: 'お気に入り率',
    icon: <Percent size={16} />,
    tooltip: {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Percent size={18} />
          お気に入り率
        </Box>
      ),
      description: 'クリック数に対するお気に入り追加数の割合',
      calculation: '<strong>お気に入り率</strong> = お気に入り数 ÷ クリック数',
      insights:
        'コンポーネントの<strong>商品訴求力</strong>を示す指標です。高いお気に入り率は、ユーザーの興味を強く引く商品を提案できていることを示唆します',
    },
    format: (value: any) => `${(safeNumber(value) * 100).toFixed(1)}%`,
    getValue: (metric: any) =>
      safeDivide(safeNumber(metric.favorite), safeNumber(metric.click)),
    width: '120px',
  },
  {
    id: 'cv',
    label: 'CV数',
    icon: <ShoppingCart size={16} />,
    tooltip: {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ShoppingCart size={18} />
          コンバージョン数（CV数）
        </Box>
      ),
      description: 'コンポーネント経由での商品購入回数',
      calculation:
        'コンポーネントから商品が<strong>購入</strong>された回数の合計',
      insights:
        'コンポーネントの<strong>最終的な成果</strong>を示す指標です。売上に直結する重要なKPIとして、提案の実質的な効果を評価します',
    },
    format: (value: any) => safeNumber(value).toLocaleString(),
    getValue: (metric: any) => safeNumber(metric.cv),
    width: '100px',
  },
  {
    id: 'cvr',
    label: 'CVR',
    icon: <BarChart2 size={16} />,
    tooltip: {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <BarChart2 size={18} />
          コンバージョン率（CVR）
        </Box>
      ),
      description: 'クリック数に対する購入数の割合',
      calculation: '<strong>コンバージョン率</strong> = CV数 ÷ クリック数',
      insights:
        'コンポーネントクリックから購買までの<strong>転換効率</strong>を示す指標です。商品の<strong>訴求力</strong>や<strong>購買意欲喚起</strong>の成功度を評価できます',
    },
    format: (value: any) => `${(safeNumber(value) * 100).toFixed(1)}%`,
    getValue: (metric: any) =>
      safeDivide(safeNumber(metric.cv), safeNumber(metric.click)),
    width: '100px',
  },
  {
    id: 'revenue',
    label: '月間換算売上',
    icon: <DollarSign size={16} />,
    tooltip: {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <DollarSign size={18} />
          月間換算売上
        </Box>
      ),
      description: '期間中の売上を1ヶ月に換算した金額',
      calculation:
        '1ヶ月あたりの想定売上 = <strong>(CV数 × 設定単価 × 30日) ÷ 集計期間日数</strong>',
      insights:
        'コンポーネントの<strong>収益貢献度</strong>を示す指標です。ビジネスインパクトを金額で評価することで、投資対効果（ROI）の判断材料となります',
    },
    format: (value: any) =>
      `¥${Math.round(safeNumber(value)).toLocaleString()}`,
    getValue: (
      metric: any,
      startDate: string,
      endDate: string,
      unitPrice: number,
    ) => {
      const daysDiff =
        differenceInDays(parseISO(endDate), parseISO(startDate)) + 1;
      const cv = safeNumber(metric.cv);
      const dailyRevenue = (cv * unitPrice) / daysDiff; // unitPrice を使用
      const monthlyRevenue = dailyRevenue * 30;
      return monthlyRevenue;
    },
    width: '140px',
  },
];

const MetricsTable: React.FC<MetricsTableProps> = ({
  metrics = [],
  startDate,
  endDate,
  unitPrice,
}) => {
  const [expandedIX, setExpandedIX] = useState(false);
  const headerRef = useRef<HTMLTableSectionElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [maxValues, setMaxValues] = useState<Record<string, number>>({});
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const columnHeaderRefs = useRef<Record<string, DOMRect>>({});

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    const calculateMaxValues = () => {
      const validMetricsArray = Array.isArray(metrics) ? metrics : [];
      const validMetrics = validMetricsArray.filter(
        (m) => m && !(m.component?.startsWith('IX') && m.type === 'component'),
      );

      const newMaxValues = columns.reduce(
        (acc, column) => {
          const values = validMetrics.map((m) => {
            if (column.id === 'revenue') {
              return (column.getValue as any)(m, startDate, endDate);
            }
            return (column.getValue as any)(m);
          });
          acc[column.id] = Math.max(0, ...values);
          return acc;
        },
        {} as Record<string, number>,
      );

      setMaxValues(newMaxValues);
    };

    calculateMaxValues();
  }, [metrics, startDate, endDate]);

  const { ixMetrics, otherMetrics, ixSummary } = React.useMemo(() => {
    const validMetricsArray = Array.isArray(metrics) ? metrics : [];
    const validMetrics = validMetricsArray.filter(
      (m) => m && !(m.component?.startsWith('IX') && m.type === 'component'),
    );

    const ixMetrics = validMetrics
      .filter((m) => m.type === 'shelf')
      .sort((a, b) => safeNumber(b.shown) - safeNumber(a.shown));

    const otherMetrics = validMetrics
      .filter((m) => m.type === 'component')
      .sort((a, b) => safeNumber(b.shown) - safeNumber(a.shown));

    const ixSummary = {
      shown: ixMetrics.reduce(
        (max, m) => Math.max(max, safeNumber(m.shown)),
        0,
      ),
      click: ixMetrics.reduce((sum, m) => sum + safeNumber(m.click), 0),
      favorite: ixMetrics.reduce((sum, m) => sum + safeNumber(m.favorite), 0),
      cv: ixMetrics.reduce((sum, m) => sum + safeNumber(m.cv), 0),
      get ctr() {
        return safeDivide(this.click, this.shown);
      },
      get favoriter() {
        return safeDivide(this.favorite, this.click);
      },
      get cvr() {
        return safeDivide(this.cv, this.click);
      },
      get revenue() {
        const daysDiff =
          differenceInDays(parseISO(endDate), parseISO(startDate)) + 1;
        const dailyRevenue = (this.cv * unitPrice) / daysDiff; // unitPrice を使用
        const monthlyRevenue = dailyRevenue * 30;
        return monthlyRevenue;
      },
    };

    return { ixMetrics, otherMetrics, ixSummary };
  }, [metrics, startDate, endDate]);

  // ヘッダー位置の更新処理
  useEffect(() => {
    const updateColumnHeaderPositions = () => {
      columns.forEach((column) => {
        const element = document.querySelector(
          `[data-column-id="${column.id}"]`,
        ) as HTMLTableCellElement;
        if (element) {
          const rect = element.getBoundingClientRect();
          columnHeaderRefs.current[column.id] = rect;
        }
      });
    };

    updateColumnHeaderPositions();
    window.addEventListener('scroll', updateColumnHeaderPositions);
    window.addEventListener('resize', updateColumnHeaderPositions);

    return () => {
      window.removeEventListener('scroll', updateColumnHeaderPositions);
      window.removeEventListener('resize', updateColumnHeaderPositions);
    };
  }, []);

  const handleColumnHover = React.useCallback((columnId: string) => {
    const rect = columnHeaderRefs.current[columnId];
    if (rect) {
      setHoveredColumn(columnId);
      setTooltipPosition({
        top: rect.top,
        left: rect.left + rect.width / 2,
      });
    }
  }, []);

  const handleColumnLeave = React.useCallback(() => {
    setHoveredColumn(null);
    setTooltipPosition(null);
  }, []);

  const STICKY_COLUMN_STYLE = {
    position: 'sticky',
    left: 0,
    zIndex: 3,
    bgcolor: 'background.paper',
    borderRight: '1px solid',
    borderRightColor: 'divider',
    boxShadow: '2px 0 4px rgba(0,0,0,0.1)',
    minWidth: '200px',
    maxWidth: '200px',
    width: '200px',
  } as const;

  const getColumnStyle = (columnId: string, isHeader: boolean = false) => ({
    position: 'relative',
    textAlign: 'right' as const,
    py: 1.5,
    px: 2,
    whiteSpace: 'nowrap' as const,
    width: columns.find((col) => col.id === columnId)?.width,
    minWidth: columns.find((col) => col.id === columnId)?.width,
    maxWidth: columns.find((col) => col.id === columnId)?.width,
    cursor: 'pointer',
    ...(isHeader && {
      bgcolor: 'background.paper',
      position: 'sticky' as const,
      top: 0,
      zIndex: 1200,
    }),
    ...(hoveredColumn === columnId && {
      '& .metrics-bar': {
        opacity: 0.3,
      },
      '& .metrics-value': {
        color: 'primary.main',
        fontWeight: 'bold',
      },
    }),
  });

  const renderMetricsBar = (value: number, maxValue: number) => {
    const percentage = maxValue > 0 ? (value / maxValue) * 100 : 0;
    const limitedPercentage = Math.min(percentage, 99);

    return (
      <Box
        className="metrics-bar"
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: `${limitedPercentage}%`,
          bgcolor: 'primary.main',
          opacity: 0.1,
          transition: 'opacity 0.2s',
        }}
      />
    );
  };

  const renderCell = (
    metric: any,
    column: ColumnDefinition,
    maxValue: number,
  ) => {
    const value =
      column.id === 'revenue'
        ? (column.getValue as any)(metric, startDate, endDate, unitPrice)
        : (column.getValue as any)(metric);

    return (
      <TableCell
        key={column.id}
        sx={{
          ...getColumnStyle(column.id),
          bgcolor: metric.type === 'shelf' ? 'grey.50' : 'background.paper',
        }}
        onMouseEnter={() => handleColumnHover(column.id)}
        onMouseLeave={handleColumnLeave}
      >
        {renderMetricsBar(value, maxValue)}
        <Typography
          variant="body2"
          className="metrics-value"
          sx={{
            position: 'relative',
            fontWeight: 'medium',
            color: 'text.primary',
          }}
        >
          {column.format(value)}
        </Typography>
      </TableCell>
    );
  };

  if (!metrics || metrics.length === 0) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={columns.length + 1} align="center">
                データがありません
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: 1000,
        borderRadius: 2,
        boxShadow: (theme) => theme.shadows[2],
        position: 'relative',
        '& .MuiTableHead-root': {
          position: 'sticky',
          top: 0,
          zIndex: 1300,
          bgcolor: 'background.paper',
        },
      }}
    >
      <Table stickyHeader size="small">
        <TableHead ref={headerRef}>
          <TableRow>
            <TableCell
              sx={{
                ...STICKY_COLUMN_STYLE,
                zIndex: 1301,
                top: 0,
                position: 'sticky',
                bgcolor: 'background.paper',
              }}
            >
              コンポーネント
            </TableCell>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                data-column-id={column.id}
                ref={(el: HTMLTableCellElement | null) =>
                  el &&
                  (columnHeaderRefs.current[column.id] =
                    el.getBoundingClientRect())
                }
                align="right"
                sx={getColumnStyle(column.id, true)}
                onMouseEnter={() => handleColumnHover(column.id)}
                onMouseLeave={handleColumnLeave}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 0.5,
                  }}
                >
                  {column.icon}
                  {column.label}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {ixMetrics.length > 0 && (
            <TableRow
              sx={{
                position: 'sticky',
                top: headerHeight,
                zIndex: 1100,
                cursor: 'pointer',
                bgcolor: 'background.paper',
                '&:hover': { bgcolor: 'action.hover' },
              }}
              onClick={() => setExpandedIX(!expandedIX)}
            >
              <TableCell
                sx={{
                  ...STICKY_COLUMN_STYLE,
                  bgcolor: 'primary.lighter',
                  zIndex: 1101,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <IconButton size="small">
                    {expandedIX ? (
                      <ChevronDown size={20} />
                    ) : (
                      <ChevronRight size={20} />
                    )}
                  </IconButton>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 'bold', color: 'primary.main' }}
                  >
                    InsightXコンポーネント ({ixMetrics.length}種類)
                  </Typography>
                </Box>
              </TableCell>
              {columns.map((column) =>
                renderCell(ixSummary, column, maxValues[column.id]),
              )}
            </TableRow>
          )}

          {expandedIX &&
            ixMetrics.map((metric) => (
              <TableRow
                key={metric.component}
                sx={{
                  bgcolor: 'grey.50',
                  '&:hover': { bgcolor: 'action.hover' },
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                <TableCell
                  sx={{
                    ...STICKY_COLUMN_STYLE,
                    bgcolor: 'grey.50',
                    pl: 6,
                    zIndex: 2,
                  }}
                >
                  <Typography variant="body2">{metric.component}</Typography>
                </TableCell>
                {columns.map((column) =>
                  renderCell(metric, column, maxValues[column.id]),
                )}
              </TableRow>
            ))}

          {otherMetrics.map((metric) => (
            <TableRow
              key={metric.component}
              sx={{
                '&:hover': { bgcolor: 'action.hover' },
                position: 'relative',
                zIndex: 1,
              }}
            >
              <TableCell
                sx={{
                  ...STICKY_COLUMN_STYLE,
                  zIndex: 2,
                }}
              >
                <Typography variant="body2">{metric.component}</Typography>
              </TableCell>
              {columns.map((column) =>
                renderCell(metric, column, maxValues[column.id]),
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {hoveredColumn && tooltipPosition && (
        <Box
          sx={{
            position: 'fixed',
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            zIndex: 9999,
            transform: 'translate(-50%, -100%)',
            pointerEvents: 'none',
          }}
        >
          {(() => {
            const column = columns.find((col) => col.id === hoveredColumn);
            return column?.tooltip ? (
              <MetricTooltip {...column.tooltip} />
            ) : null;
          })()}
        </Box>
      )}
    </TableContainer>
  );
};

export default MetricsTable;
