interface PageTypeInfo {
  label: string;
  description: string;
  color: string;
}

export const getPageTypeInfo = (pageType: string): PageTypeInfo => {
  const pageTypeMap: Record<string, PageTypeInfo> = {
    // 既存のページタイプ
    top: {
      label: 'トップページ',
      description: 'ECサイトのメインページ',
      color: '#4ECDC4',
    },
    pdp: {
      label: '商品詳細',
      description: '商品の詳細情報ページ',
      color: '#FF6B6B',
    },
    list: {
      label: '検索結果',
      description: '商品検索・一覧ページ',
      color: '#FFE66D',
    },
    brand: {
      label: 'ブランド',
      description: 'ブランドページ',
      color: '#6C5CE7',
    },
    'shop(incl.brand)': {
      label: 'ブランド',
      description: 'ブランドページ',
      color: '#45B7AF',
    },
    other: {
      label: 'その他',
      description: 'その他のページタイプ',
      color: '#A8A4F0',
    },

    // 新規追加のページタイプ
    cart_show: {
      label: 'カート',
      description: 'ショッピングカートページ',
      color: '#E66161',
    },
    favorite_list: {
      label: 'お気に入り',
      description: 'お気に入り商品一覧ページ',
      color: '#CCB857',
    },
    mypage: {
      label: 'マイページ',
      description: '会員情報・各種設定ページ',
      color: '#8480EC',
    },
    order_complete: {
      label: '注文完了',
      description: '注文完了・サンクスページ',
      color: '#3CA29A',
    },
    order_history: {
      label: '注文履歴',
      description: '過去の注文履歴一覧ページ',
      color: '#CC5757',
    },
    top_all: {
      label: 'トップ（すべて）',
      description: 'トップページ',
      color: '#B3A14C',
    },
    top_kids: {
      label: 'キッズトップ',
      description: 'キッズカテゴリのメインページ',
      color: '#615AD3',
    },
    top_women: {
      label: 'レディーストップ',
      description: 'レディースカテゴリのメインページ',
      color: '#338D86',
    },
    top_men: {
      label: 'メンズトップ',
      description: 'メンズカテゴリのメインページ',
      color: '#B34D4D',
    },
    info: {
      label: 'インフォメーション',
      description: 'お知らせ・ヘルプページ',
      color: '#998A41',
    },
    news: {
      label: 'ニュース',
      description: 'ニュース・プレスリリースページ',
      color: '#5550BF',
    },
    shop: {
      label: 'ショップ',
      description: 'ブランド・ショップ別商品一覧ページ',
      color: '#2A7871',
    },
    ttop: {
      label: 'トップ',
      description: 'トップオブトップページ',
      color: '#994343',
    },
    content: {
      label: 'コンテンツ',
      description: 'セールLPページ・キャンペーンページ等',
      color: '#9E8E3C',
    },
    ranking: {
      label: 'ランキング',
      description: '人気商品ランキングページ',
      color: '#4D48A6',
    },
    category_search: {
      label: 'カテゴリ検索',
      description: 'カテゴリ商品検索ページ',
      color: '#226159',
    },
    history: {
      label: '閲覧履歴',
      description: '閲覧履歴ページ',
      color: '#803939',
    },
  };

  return (
    pageTypeMap[pageType] ?? {
      label: pageType,
      description: '',
      color: '#999999', // デフォルトの色
    }
  );
};

// ラベルのみを取得する便利関数
export const getPageTypeLabel = (pageType: string): string => {
  return getPageTypeInfo(pageType).label;
};

// 説明のみを取得する便利関数
export const getPageTypeDescription = (pageType: string): string => {
  return getPageTypeInfo(pageType).description;
};

// 色を取得する便利関数
export const getPageTypeColor = (pageType: string): string => {
  return getPageTypeInfo(pageType).color;
};
