import { Box, Typography } from '@mui/material';
import React from 'react';
import ShelfPerformance from '../shelf-performance/ShelfPerformance';

interface ShelfPerformanceSectionProps {
  startDate: string;
  endDate: string;
  selectedPageType: string;
  onPageTypeChange: (pageType: string) => void;
  unitPrice: number;
}

const ShelfPerformanceSection: React.FC<ShelfPerformanceSectionProps> = ({
  startDate,
  endDate,
  selectedPageType,
  onPageTypeChange,
  unitPrice,
}) => {
  return (
    <Box
      sx={{
        mb: 6,
        bgcolor: 'background.paper',
        borderRadius: 3,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          p: 3,
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: 'primary.main',
          color: 'white',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          シェルフ別パフォーマンス
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5 }}>
          シェルフタイプ別の詳細なパフォーマンス指標
        </Typography>
      </Box>

      <Box sx={{ p: 3 }}>
        <ShelfPerformance
          startDate={startDate}
          endDate={endDate}
          selectedPageType={selectedPageType}
          onPageTypeChange={onPageTypeChange}
          unitPrice={unitPrice}
        />
      </Box>
    </Box>
  );
};

export default ShelfPerformanceSection;
