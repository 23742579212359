import { Box, Card, CardContent, Typography } from '@mui/material';
import { format, parseISO, startOfMonth, startOfWeek } from 'date-fns';
import { ja } from 'date-fns/locale';
import { Layout } from 'lucide-react';
import React, { useMemo } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DailyShelfMetrics } from '../../../services/shelf-service';
import {
  getPageTypeColor,
  getPageTypeLabel,
} from '../../../utils/page-type-utils';

interface PageTypeMetricsChartsProps {
  data: DailyShelfMetrics[];
  timeUnit: string;
}

interface ChartConfig {
  id: 'shown' | 'click' | 'cv' | 'revenue';
  title: string;
  description: string;
  valueFormat: (value: number) => string;
  yAxisWidth?: number;
}

const CustomTooltip = ({ active, payload, label, config }: any) => {
  if (!active || !payload) return null;

  const total = payload.reduce(
    (sum: number, entry: any) => sum + (entry.value || 0),
    0,
  );
  const sortedPayload = [...payload].sort((a, b) => b.value - a.value);

  return (
    <Box
      sx={{
        bgcolor: 'rgba(255, 255, 255, 0.95)',
        p: 2,
        borderRadius: 1,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
        border: '1px solid rgba(0, 0, 0, 0.05)',
      }}
    >
      <Typography
        variant="body2"
        sx={{ mb: 1, fontWeight: 'bold', color: 'text.primary' }}
      >
        {format(parseISO(label), 'yyyy/M/d (E)', { locale: ja })}
      </Typography>
      {sortedPayload.map((entry: any) => (
        <Box
          key={entry.dataKey}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 0.5,
            gap: 1,
          }}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              bgcolor: entry.color,
              borderRadius: '50%',
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              minWidth: 120,
            }}
          >
            {entry.name}:
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            {config.valueFormat(entry.value)}
          </Typography>
        </Box>
      ))}
      <Box
        sx={{
          mt: 1,
          pt: 1,
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          合計: {config.valueFormat(total)}
        </Typography>
      </Box>
    </Box>
  );
};

const PageTypeMetricsCharts: React.FC<PageTypeMetricsChartsProps> = ({
  data,
  timeUnit,
}) => {
  const sortedPageTypes = useMemo(() => {
    const pageTypeShownTotals = data.reduce(
      (acc, curr) => {
        acc[curr.url_page_type] = (acc[curr.url_page_type] || 0) + curr.shown;
        return acc;
      },
      {} as { [key: string]: number },
    );

    return Object.entries(pageTypeShownTotals)
      .sort(([, a], [, b]) => b - a)
      .map(([pageType]) => pageType);
  }, [data]);

  const chartConfigs: ChartConfig[] = [
    {
      id: 'shown',
      title: 'シェルフ表示数',
      description:
        '各ページでシェルフが何セッションに表示されたかを集計したグラフです',
      valueFormat: (value: number) => value.toLocaleString(),
      yAxisWidth: 80,
    },
    {
      id: 'click',
      title: 'シェルフクリック数',
      description:
        '各ページでシェルフが何セッションでクリックされたかを集計したグラフです',
      valueFormat: (value: number) => value.toLocaleString(),
      yAxisWidth: 60,
    },
    {
      id: 'cv',
      title: 'シェルフ経由CVSS数',
      description:
        '各ページでシェルフ経由で何セッションがコンバージョンしたかを集計したグラフです',
      valueFormat: (value: number) => value.toLocaleString(),
      yAxisWidth: 60,
    },
    {
      id: 'revenue',
      title: 'シェルフ経由売上（百万円）',
      description:
        '各ページでのシェルフクリック後の購買額です（CVしたセッション数に単価を掛けて算出）',
      valueFormat: (value: number) =>
        value.toLocaleString(undefined, { maximumFractionDigits: 1 }),
      yAxisWidth: 70,
    },
  ];

  const getTimeUnitStartDate = (date: string) => {
    const dateObj = parseISO(date);
    switch (timeUnit) {
      case 'week':
        return format(startOfWeek(dateObj, { locale: ja }), 'yyyy-MM-dd');
      case 'month':
        return format(startOfMonth(dateObj), 'yyyy-MM-dd');
      default:
        return date;
    }
  };

  const processData = (metrics: DailyShelfMetrics[], metric: string): any[] => {
    const groupedData = metrics.reduce(
      (acc, curr) => {
        const date = curr.date;
        const key = getTimeUnitStartDate(date);

        if (!acc[key]) {
          acc[key] = { date: key, total: 0 };
        }

        const pageTypeLabel = getPageTypeLabel(curr.url_page_type);
        const value =
          metric === 'revenue' ? curr[metric] / 1000000 : curr[metric];

        acc[key][pageTypeLabel] = (acc[key][pageTypeLabel] || 0) + value;
        acc[key].total += value;

        return acc;
      },
      {} as { [key: string]: any },
    );

    return Object.values(groupedData).sort((a, b) =>
      a.date.localeCompare(b.date),
    );
  };

  const formatXAxisTick = (date: string) => {
    switch (timeUnit) {
      case 'week':
        return format(parseISO(date), 'M/d週', { locale: ja });
      case 'month':
        return format(parseISO(date), 'yyyy/M月', { locale: ja });
      default:
        return format(parseISO(date), 'M/d', { locale: ja });
    }
  };

  return (
    <Box sx={{ mb: 6 }}>
      <Typography
        variant="h5"
        sx={{
          mb: 3,
          fontWeight: 'bold',
          color: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Layout size={24} />
        ページタイプ別パフォーマンス
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 4,
        }}
      >
        {chartConfigs.map((config) => {
          const chartData = processData(data, config.id);

          return (
            <Card
              key={config.id}
              sx={{
                height: '100%',
                borderRadius: 2,
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                bgcolor: '#FFFFFF',
              }}
            >
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.25rem',
                      color: 'text.primary',
                      mb: 1,
                    }}
                  >
                    {config.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {config.description}
                  </Typography>
                </Box>
                <Box sx={{ width: '100%', height: 360 }}>
                  <ResponsiveContainer>
                    <AreaChart
                      data={chartData}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 0,
                        bottom: 60,
                      }}
                    >
                      <defs>
                        {sortedPageTypes.map((pageType) => {
                          const color = getPageTypeColor(pageType);
                          return (
                            <linearGradient
                              key={pageType}
                              id={`gradient-${pageType}`}
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor={color}
                                stopOpacity={0.95}
                              />
                              <stop
                                offset="95%"
                                stopColor={color}
                                stopOpacity={0.3}
                              />
                            </linearGradient>
                          );
                        })}
                      </defs>
                      <CartesianGrid
                        strokeDasharray="3 3"
                        vertical={false}
                        stroke="rgba(0, 0, 0, 0.1)"
                      />
                      <XAxis
                        dataKey="date"
                        tickFormatter={formatXAxisTick}
                        tick={{ fontSize: 12, fill: '#666' }}
                        tickLine={{ stroke: '#ccc' }}
                        axisLine={{ stroke: '#ccc' }}
                      />
                      <YAxis
                        width={config.yAxisWidth}
                        tickFormatter={(value) => config.valueFormat(value)}
                        tick={{ fontSize: 12, fill: '#666' }}
                        tickLine={{ stroke: '#ccc' }}
                        axisLine={{ stroke: '#ccc' }}
                      />
                      <Tooltip
                        content={(props) => (
                          <CustomTooltip {...props} config={config} />
                        )}
                      />
                      <Legend
                        layout="horizontal"
                        verticalAlign="bottom"
                        align="center"
                        wrapperStyle={{
                          paddingTop: '20px',
                          fontSize: '12px',
                        }}
                      />
                      {sortedPageTypes.map((pageType) => {
                        const label = getPageTypeLabel(pageType);
                        const color = getPageTypeColor(pageType);
                        return (
                          <Area
                            key={pageType}
                            type="monotone"
                            dataKey={label}
                            name={label}
                            stackId="1"
                            stroke={color}
                            strokeWidth={1}
                            fill={`url(#gradient-${pageType})`}
                          />
                        );
                      })}
                    </AreaChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default PageTypeMetricsCharts;
