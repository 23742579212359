import { Box, Typography } from '@mui/material';
import React from 'react';
import { ComponentMetrics } from '../../../services/component-service';
import CustomPageTabs from '../CustomPageTabs';
import MetricsTable from '../component-comparison/MetricsTable';

interface ComparisonSectionProps {
  metrics: ComponentMetrics[];
  selectedPageType: string;
  onPageTypeChange: (event: React.SyntheticEvent, newValue: string) => void;
  startDate: string;
  endDate: string;
  unitPrice: number;
}

const ComparisonSection: React.FC<ComparisonSectionProps> = ({
  metrics,
  selectedPageType,
  onPageTypeChange,
  startDate,
  endDate,
  unitPrice,
}) => {
  const metricsByPageType: Record<string, ComponentMetrics[]> = {};
  metrics.forEach((metric) => {
    const pageType = metric.url_page_type;
    if (!metricsByPageType[pageType]) {
      metricsByPageType[pageType] = [];
    }
    metricsByPageType[pageType].push(metric);
  });

  const desiredPageTypeOrder = ['top', 'pdp', 'list', 'brand', 'other'];
  const pageTypes = Object.keys(metricsByPageType).sort(
    (a, b) => desiredPageTypeOrder.indexOf(a) - desiredPageTypeOrder.indexOf(b),
  );

  return (
    <Box
      sx={{
        mb: 6,
        bgcolor: 'background.paper',
        borderRadius: 3,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          p: 3,
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: 'primary.main',
          color: 'white',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          他コンポーネントとの比較
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5 }}>
          各ページタイプにおけるコンポーネントのパフォーマンス詳細
        </Typography>
      </Box>

      <Box sx={{ px: 3, pt: 2 }}>
        <CustomPageTabs
          pageTypes={pageTypes}
          selectedPageType={selectedPageType}
          onChange={onPageTypeChange}
          metrics={metrics}
          startDate={startDate}
          endDate={endDate}
        />
      </Box>

      <Box sx={{ p: 3 }}>
        {selectedPageType && metricsByPageType[selectedPageType] && (
          <MetricsTable
            metrics={metricsByPageType[selectedPageType]}
            startDate={startDate}
            endDate={endDate}
            unitPrice={unitPrice}
          />
        )}
      </Box>
    </Box>
  );
};

export default ComparisonSection;
