import { ColumnDefinition } from './types';
import { safeNumber } from './utils';

export const columns: ColumnDefinition[] = [
  {
    id: 'shown',
    label: '表示数',
    tooltipTitle: '表示数',
    tooltipDescription: '期間中にユーザーの画面に表示された回数',
    tooltipCalculation:
      'ページが<strong>表示</strong>され、<strong>シェルフが画面内に入った</strong>回数の合計',
    tooltipInsights:
      'シェルフの<strong>リーチ</strong>を示す基本指標です。表示数が多いほど、より多くのユーザーにシェルフが表示されていることを意味します',
    format: (value: number | null | undefined) =>
      safeNumber(value).toLocaleString(),
    width: '140px',
    iconType: 'eye',
  },
  {
    id: 'scroll',
    label: 'スクロール数',
    tooltipTitle: 'スクロール数',
    tooltipDescription: 'シェルフまでスクロールされた回数',
    tooltipCalculation:
      'ユーザーが<strong>シェルフの位置まで</strong>スクロールした回数の合計',
    tooltipInsights:
      'ユーザーの<strong>興味喚起</strong>の度合いを示す指標です。ページ内でのシェルフの<strong>位置や視認性</strong>の評価に役立ちます',
    format: (value: number | null | undefined) =>
      safeNumber(value).toLocaleString(),
    width: '140px',
    iconType: 'scroll',
  },
  {
    id: 'scrollr',
    label: 'スクロール率',
    tooltipTitle: 'スクロール率',
    tooltipDescription: '表示数に対するスクロール数の割合',
    tooltipCalculation: '<strong>スクロール率</strong> = スクロール数 ÷ 表示数',
    tooltipInsights:
      'シェルフの<strong>露出効率</strong>を示す指標です。ユーザーがどれだけシェルフまで到達しているかを評価できます',
    format: (value: number | null | undefined) =>
      `${safeNumber(value).toFixed(1)}%`,
    width: '120px',
    iconType: 'barchart',
  },
  {
    id: 'click',
    label: 'クリック数',
    tooltipTitle: 'クリック数',
    tooltipDescription: 'シェルフ内の商品をクリックした回数',
    tooltipCalculation:
      'シェルフ内で商品が<strong>クリック</strong>された回数の合計',
    tooltipInsights:
      'ユーザーの<strong>興味関心</strong>の度合いを示す重要指標です。シェルフの精度や表示の適切さを評価する基準となります',
    format: (value: number | null | undefined) =>
      safeNumber(value).toLocaleString(),
    width: '140px',
    iconType: 'click',
  },
  {
    id: 'clickr',
    label: 'クリック率',
    tooltipTitle: 'クリック率（CTR）',
    tooltipDescription: '表示数に対するクリック数の割合',
    tooltipCalculation: '<strong>クリック率</strong> = クリック数 ÷ 表示数',
    tooltipInsights:
      'シェルフの<strong>魅力度</strong>と<strong>関連性</strong>を示す指標です。高いCTRは、ユーザーのニーズに合った商品を提案できていることを示唆します',
    format: (value: number | null | undefined) =>
      `${safeNumber(value).toFixed(1)}%`,
    width: '120px',
    iconType: 'barchart',
  },
  {
    id: 'cv',
    label: 'CV数',
    tooltipTitle: 'コンバージョン数（CV数）',
    tooltipDescription: 'シェルフ経由での商品購入回数',
    tooltipCalculation: 'シェルフ商品が<strong>購入</strong>された回数の合計',
    tooltipInsights:
      'シェルフの<strong>最終的な成果</strong>を示す指標です。売上に直結する重要なKPIとして、レコメンドの実質的な効果を評価します',
    format: (value: number | null | undefined) =>
      safeNumber(value).toLocaleString(),
    width: '120px',
    iconType: 'cart',
  },
  {
    id: 'cvr',
    label: 'CVR',
    tooltipTitle: 'コンバージョン率（CVR）',
    tooltipDescription: 'クリック数に対する購入数の割合',
    tooltipCalculation: '<strong>コンバージョン率</strong> = CV数 ÷ クリック数',
    tooltipInsights:
      'シェルフクリックから購買までの<strong>転換効率</strong>を示す指標です。商品の<strong>訴求力</strong>や<strong>購買意欲喚起</strong>の成功度を評価できます',
    format: (value: number | null | undefined) =>
      `${safeNumber(value).toFixed(1)}%`,
    width: '100px',
    iconType: 'percent',
  },
  {
    id: 'revenue',
    label: '月間換算売上',
    tooltipTitle: '月間換算売上',
    tooltipDescription: '期間中の売上を1ヶ月に換算した金額',
    tooltipCalculation:
      '1ヶ月あたりの想定売上 = <strong>(CV数 × 設定単価 × 30日) ÷ 集計期間日数</strong>',
    tooltipInsights:
      'シェルフの<strong>収益貢献度</strong>を示す指標です。ビジネスインパクトを金額で評価することで、投資対効果（ROI）の判断材料となります',
    format: (value) => {
      if (value === null || value === undefined) return '¥0';
      return `¥${Math.round(value).toLocaleString()}`;
    },
    width: '150px',
    iconType: 'dollar',
  },
];
