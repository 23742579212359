import { Paper, useTheme } from '@mui/material';
import { endOfDay, format, startOfDay, subDays, subMonths } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import type { DateRange, RangeType } from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';

interface CustomDateRangePickerProps {
  startDate: string;
  endDate: string;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
}

const yesterday = endOfDay(subDays(new Date(), 1));

const ranges: RangeType<DateRange>[] = [
  {
    label: '昨日',
    value: () => [startOfDay(yesterday), endOfDay(yesterday)],
  },
  {
    label: '過去7日間',
    value: () => [startOfDay(subDays(yesterday, 6)), endOfDay(yesterday)],
  },
  {
    label: '過去30日間',
    value: () => [startOfDay(subDays(yesterday, 29)), endOfDay(yesterday)],
  },
  {
    label: '過去3ヶ月',
    value: () => [startOfDay(subMonths(yesterday, 3)), endOfDay(yesterday)],
  },
  {
    label: '過去6ヶ月',
    value: () => [startOfDay(subMonths(yesterday, 6)), endOfDay(yesterday)],
  },
];

export const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const theme = useTheme();

  const handleChange = (dates: [Date, Date] | null) => {
    if (dates) {
      onStartDateChange(format(dates[0], 'yyyy-MM-dd'));
      onEndDateChange(format(dates[1], 'yyyy-MM-dd'));
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        '& .rs-picker': {
          width: '100%',
        },
        '& .rs-picker-toggle': {
          backgroundColor: '#fff',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          height: '42px',
          paddingTop: '8px',
          paddingBottom: '8px',
          '&:hover, &:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }}
    >
      <DateRangePicker
        ranges={ranges}
        value={[new Date(startDate), new Date(endDate)]}
        onChange={handleChange}
        placeholder="期間を選択"
        format="yyyy-MM-dd"
        character="～"
        locale={{
          sunday: '日',
          monday: '月',
          tuesday: '火',
          wednesday: '水',
          thursday: '木',
          friday: '金',
          saturday: '土',
          ok: '確定',
          today: '今日',
          yesterday: '昨日',
          last7Days: '過去7日間',
          hours: '時',
          minutes: '分',
          seconds: '秒',
        }}
        menuStyle={{
          zIndex: 1400,
        }}
        cleanable={false}
        toggleAs="div"
        size="lg"
        block
        disabledDate={(date) => date > yesterday}
      />
    </Paper>
  );
};

export default CustomDateRangePicker;
