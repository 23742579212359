import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';

export type TimeUnit = 'day' | 'week' | 'month';

interface TimeUnitSelectorProps {
  value: TimeUnit;
  onChange: (value: TimeUnit) => void;
}

const TimeUnitSelector: React.FC<TimeUnitSelectorProps> = ({
  value,
  onChange,
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: TimeUnit | null,
  ) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-start' }}>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        size="small"
        sx={{
          '& .MuiToggleButton-root': {
            px: 3,
            py: 0.5,
            '&.Mui-selected': {
              backgroundColor: 'primary.main',
              color: 'white',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            },
          },
        }}
      >
        <ToggleButton value="day">日次</ToggleButton>
        <ToggleButton value="week">週次</ToggleButton>
        <ToggleButton value="month">月次</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default TimeUnitSelector;
