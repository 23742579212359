// LoginPage.tsx
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  loginWithCredentials,
  loginWithGoogle,
} from '../../services/auth-service';
import { useAuth } from '../../store/auth.store';

const LoginPage: React.FC = () => {
  const { auth, login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    // If already authenticated, redirect to dashboard
    if (auth.isAuthenticated) {
      navigate('/dashboard/metrics-viewer');
    }
  }, [auth.isAuthenticated, navigate]);

  const onSuccess = async (credentialResponse: CredentialResponse) => {
    if (!credentialResponse.credential) {
      setError('Googleログイン情報が取得できませんでした。');
      return;
    }

    setIsLoading(true);
    try {
      const response = await loginWithGoogle(credentialResponse.credential);
      if (!response || !response.user || !response.token) {
        throw new Error('サーバーからの応答が不正です');
      }

      // Verify user has necessary data
      if (!response.user.username || !response.user.menuItems) {
        throw new Error('ユーザー情報が不完全です');
      }

      login(response.user, response.token);
      navigate('/dashboard/metrics-viewer');
    } catch (err) {
      console.error('Login Failed:', err);
      setError(
        err instanceof Error
          ? err.message
          : 'Googleログインに失敗しました。もう一度お試しください。',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    setError('Googleログインに失敗しました。もう一度お試しください。');
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!username || !password) {
      setError('ユーザー名とパスワードを入力してください。');
      return;
    }

    setIsLoading(true);
    try {
      const response = await loginWithCredentials(username, password);
      if (response && response.user && response.token) {
        login(response.user, response.token);
        navigate('/dashboard/metrics-viewer');
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Login Failed:', err);
      setError(
        'ログインに失敗しました。ユーザー名とパスワードを確認してください。',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
            InsightX Dashboard
          </Typography>

          {error && (
            <Alert
              severity="error"
              onClose={() => setError(null)}
              sx={{ mb: 2, width: '100%' }}
            >
              {error}
            </Alert>
          )}

          <Box
            component="form"
            onSubmit={handleLogin}
            sx={{ mt: 1, width: '100%' }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="ユーザー名"
              name="username"
              autoComplete="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={isLoading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, color: 'white', fontSize: 20 }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'ログイン'}
            </Button>

            {!isLoading && (
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <GoogleLogin
                  onSuccess={onSuccess}
                  onError={onError}
                  useOneTap
                  theme="outline"
                  shape="pill"
                  size="large"
                />
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginPage;
