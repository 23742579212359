import { differenceInDays, parseISO } from 'date-fns';
import { ShelfMetrics } from '../../../services/shelf-service';

export const safeNumber = (
  value: number | null | undefined | string,
): number => {
  if (value === null || value === undefined) {
    return 0;
  }
  const num = typeof value === 'string' ? parseFloat(value) : value;
  return isNaN(num) ? 0 : num;
};

export const calculateMonthlyRevenue = (
  cv: number,
  unitPrice: number,
  startDate: string,
  endDate: string,
): number => {
  const daysDiff = differenceInDays(parseISO(endDate), parseISO(startDate)) + 1;
  const dailyRevenue = safeNumber(cv * unitPrice) / daysDiff;
  return dailyRevenue * 30;
};

export const calculateSummaryMetrics = (
  metrics: ShelfMetrics[],
  unitPrice: number,
  startDate: string,
  endDate: string,
): ShelfMetrics => {
  const summaryMetrics = metrics.reduce((acc, metric) => {
    // 基本的な数値の合計
    ['shown', 'scroll', 'click', 'cv', 'cart', 'favorite'].forEach((key) => {
      const typedKey = key as keyof ShelfMetrics;
      (acc[typedKey] as number) =
        safeNumber(acc[typedKey]) + safeNumber(metric[typedKey]);
    });
    return acc;
  }, {} as Partial<ShelfMetrics>) as ShelfMetrics;

  // 率の計算
  const shown = safeNumber(summaryMetrics.shown);
  const click = safeNumber(summaryMetrics.click);
  const cv = safeNumber(summaryMetrics.cv);

  summaryMetrics.scrollr =
    shown > 0 ? (safeNumber(summaryMetrics.scroll) / shown) * 100 : 0;
  summaryMetrics.clickr = shown > 0 ? (click / shown) * 100 : 0;
  summaryMetrics.cvr = click > 0 ? (cv / click) * 100 : 0;
  summaryMetrics.cartr =
    click > 0 ? (safeNumber(summaryMetrics.cart) / click) * 100 : 0;
  summaryMetrics.favoriter =
    click > 0 ? (safeNumber(summaryMetrics.favorite) / click) * 100 : 0;
  summaryMetrics.revenue = calculateMonthlyRevenue(
    cv,
    unitPrice,
    startDate,
    endDate,
  );

  return summaryMetrics;
};

export const aggregateMetricsByGroups = (
  metrics: ShelfMetrics[],
  unitPrice: number,
  startDate: string,
  endDate: string,
): ShelfMetrics[] => {
  const groupedMetrics = new Map<string, ShelfMetrics[]>();

  metrics.forEach((metric) => {
    const key = `${metric.shelf_type}-${metric.gray_tagline}-${metric.bold_tagline}`;
    if (!groupedMetrics.has(key)) {
      groupedMetrics.set(key, []);
    }
    groupedMetrics.get(key)!.push(metric);
  });

  return Array.from(groupedMetrics.values()).map((group) => {
    const summary = calculateSummaryMetrics(
      group,
      unitPrice,
      startDate,
      endDate,
    );
    return {
      ...summary,
      shelf_type: group[0].shelf_type,
      gray_tagline: group[0].gray_tagline,
      bold_tagline: group[0].bold_tagline,
      url_page_type: 'all',
    };
  });
};
