import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { getShelfById } from '../../services/shelf-builder/get-shelf-by-id';
import { postStgSearchShelfQueries } from '../../services/shelf-builder/post-stg-search-shelf-queries';
import { postShelfTaglinesKnn } from '../../services/shelf-previewer/post-shelf-taglines-knn';
import ItemSlider from './ItemSlider';
import ReviewResult from './ReviewResult';
import { ShelfState, SimilarShelvesState } from './ShelfBuilder';
import SimilarShelves from './SimilarShelves';
import { InputContainer, ShelfContainer, StyledTextField } from './styles';

interface ShelfEditorProps {
  shelf: ShelfState;
  onChange: (updatedShelf: ShelfState) => void;
  updateSimilarShelves: (
    shelfId: string,
    similarShelves: SimilarShelvesState,
  ) => void;
  similarShelves: SimilarShelvesState;
  onSearch: () => void;
  onLoadMore: () => void;
}

const ShelfEditor: React.FC<ShelfEditorProps> = ({
  shelf,
  onChange,
  updateSimilarShelves,
  similarShelves,
  onSearch,
  onLoadMore,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleFieldChange = useCallback(
    (field: keyof ShelfState, value: string) => {
      onChange({ ...shelf, [field]: value });
    },
    [shelf, onChange],
  );

  const saveShelf = useCallback(
    async (shelfData: Partial<ShelfState>) => {
      try {
        onChange({ ...shelf, updating: true });
        await postStgSearchShelfQueries([
          {
            shelf_id: shelf.shelf_id,
            tagline_1: shelf.tagline_1,
            tagline_2: shelf.tagline_2,
            where_condition: shelf.where_condition,
            is_deleted: shelf.is_deleted,
            item_count: shelf.item_count,
            ...shelfData,
          },
        ]);
        onChange({ ...shelf, ...shelfData, updating: false });
      } catch (error) {
        console.error(error);
        onChange({ ...shelf, updating: false });
      }
    },
    [shelf, onChange],
  );

  const handleFieldBlur = useCallback(async () => {
    if (shelf.updating) return;
    await saveShelf({});
  }, [shelf, saveShelf]);

  const handleWhereConditionBlur = useCallback(async () => {
    if (!shelf.where_condition) return;
    onSearch();
  }, [shelf.where_condition, onSearch]);

  const handleTaglineBlur = useCallback(async () => {
    if (!shelf.tagline_2) return;
    try {
      const knnResults = await postShelfTaglinesKnn([shelf.tagline_2]);
      updateSimilarShelves(shelf.shelf_id, knnResults[shelf.tagline_2]);
    } catch (error) {
      console.error('Error fetching similar shelves:', error);
    }
  }, [shelf.shelf_id, shelf.tagline_2, updateSimilarShelves]);

  const handleToggleDelete = useCallback(async () => {
    try {
      onChange({ ...shelf, updating: true });
      await postStgSearchShelfQueries([
        {
          shelf_id: shelf.shelf_id,
          tagline_1: shelf.tagline_1,
          tagline_2: shelf.tagline_2,
          where_condition: shelf.where_condition,
          is_deleted: !shelf.is_deleted,
          item_count: shelf.item_count,
        },
      ]);

      const updatedShelfData = await getShelfById(shelf.shelf_id);
      onChange({ ...shelf, ...updatedShelfData, updating: false });
    } catch (error) {
      console.error(error);
      onChange({ ...shelf, updating: false });
    }
  }, [shelf, onChange]);

  const handleAccordionChange = useCallback(() => {
    setExpanded((prev) => {
      const newVal = !prev;
      // アコーディオンが開かれた瞬間にonSearchをコール
      if (newVal && shelf.items?.length === 0 && shelf.where_condition) {
        onSearch();
      }
      return newVal;
    });
  }, [onSearch, shelf.items, shelf.where_condition]);

  return (
    <ShelfContainer $isDeleted={shelf.is_deleted}>
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            {shelf.tagline_2 || 'No tagline'}
          </Typography>
          <Typography variant="body2" sx={{ color: '#666' }}>
            アイテム数:
            {shelf.loading ? '取得中...' : shelf.items ? shelf.items.length : 0}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleToggleDelete}
            size="small"
            sx={{ position: 'absolute', top: 16, right: 52 }}
          >
            {shelf.is_deleted ? '復活' : '削除'}
          </Button>
          {shelf.updating && (
            <CircularProgress
              size={24}
              sx={{ position: 'absolute', top: 16, right: 100 }}
            />
          )}

          <InputContainer>
            <StyledTextField
              label="タグライン1"
              value={shelf.tagline_1}
              onChange={(e) => handleFieldChange('tagline_1', e.target.value)}
              onBlur={handleFieldBlur}
              size="small"
              placeholder="タグライン1を入力"
              InputLabelProps={{
                shrink: true,
              }}
              $isEmpty={!shelf.tagline_1}
            />
            <StyledTextField
              label="タグライン2"
              value={shelf.tagline_2}
              onChange={(e) => handleFieldChange('tagline_2', e.target.value)}
              onBlur={() => {
                handleFieldBlur();
                handleTaglineBlur();
              }}
              size="small"
              placeholder="タグライン2を入力"
              InputLabelProps={{
                shrink: true,
              }}
              $isEmpty={!shelf.tagline_2}
            />
            <StyledTextField
              label="Where条件"
              value={shelf.where_condition}
              onChange={(e) =>
                handleFieldChange('where_condition', e.target.value)
              }
              onBlur={handleWhereConditionBlur}
              size="small"
              multiline
              rows={2}
              placeholder="Where条件を入力"
              InputLabelProps={{
                shrink: true,
              }}
              $isEmpty={!shelf.where_condition}
            />
          </InputContainer>

          <ReviewResult
            score={shelf.review_score}
            reason={shelf.review_reason}
          />
          <SimilarShelves similarShelves={similarShelves} />

          {shelf.loading ? (
            <CircularProgress />
          ) : (
            expanded &&
            shelf.visibleItems &&
            shelf.visibleItems.length > 0 && (
              <ItemSlider
                items={shelf.items || []}
                visibleItems={shelf.visibleItems}
                onLoadMore={onLoadMore}
              />
            )
          )}
        </AccordionDetails>
      </Accordion>
    </ShelfContainer>
  );
};

export default ShelfEditor;
