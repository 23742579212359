import { Box, Card, Typography } from '@mui/material';
import {
  Calculator,
  DollarSign,
  Eye,
  HelpCircle,
  Lightbulb,
  LineChart,
  MousePointerClick,
  TrendingDown,
  TrendingUp,
  Users,
} from 'lucide-react';
import React, { useState } from 'react';
import { DailySSMetrics } from '../../../services/shelf-service';

interface MetricsSummaryCardsProps {
  data: DailySSMetrics[];
  unitPrice: number;
}

interface MetricDefinition {
  label: string;
  value: string;
  color: string;
  icon: React.ReactNode;
  description: string;
  calculation: string;
  insights: string;
  interpretation: {
    high: string;
    low: string;
  };
}

interface BaseInfoBoxProps {
  title: string;
  icon: React.ReactNode;
}

interface TextInfoBoxProps extends BaseInfoBoxProps {
  type: 'text';
  children: string;
}

interface InterpretationInfoBoxProps extends BaseInfoBoxProps {
  type: 'interpretation';
  interpretation: {
    high: string;
    low: string;
  };
}

type InfoBoxProps = TextInfoBoxProps | InterpretationInfoBoxProps;

const InfoBox: React.FC<InfoBoxProps> = (props) => {
  const { title, icon } = props;

  if (props.type === 'interpretation') {
    const { interpretation } = props;
    return (
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="subtitle2"
          sx={{
            color: 'text.secondary',
            fontWeight: 'bold',
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {icon} {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            pl: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              p: 1,
              borderRadius: 1,
              bgcolor: 'rgba(76, 175, 80, 0.1)',
              color: '#2e7d32',
              fontSize: '0.875rem',
            }}
          >
            <TrendingUp size={16} />
            <Typography variant="body2">{interpretation.high}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              p: 1,
              borderRadius: 1,
              bgcolor: 'rgba(244, 67, 54, 0.1)',
              color: '#d32f2f',
              fontSize: '0.875rem',
            }}
          >
            <TrendingDown size={16} />
            <Typography variant="body2">{interpretation.low}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Typography
        variant="subtitle2"
        sx={{
          color: 'text.secondary',
          fontWeight: 'bold',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {icon} {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          pl: 3,
          '& strong': {
            fontWeight: 'bold',
            color: 'text.primary',
          },
        }}
        dangerouslySetInnerHTML={{ __html: props.children }}
      />
    </Box>
  );
};

const MetricsSummaryCards: React.FC<MetricsSummaryCardsProps> = ({
  data,
  unitPrice,
}) => {
  const [hoveredCard, setHoveredCard] = useState<string | null>(null);

  const totals = data.reduce(
    (acc, curr) => ({
      totalShownSS: acc.totalShownSS + curr.ss,
      shelfShownSS: acc.shelfShownSS + curr.shelf_shown,
      shelfClickSS: acc.shelfClickSS + curr.shelf_click,
      shelfRevenue: acc.shelfRevenue + curr.shelf_cv * unitPrice, // 修正：単価を掛ける
      totalRevenue: acc.totalRevenue + curr.cv * unitPrice, // 修正：単価を掛ける
    }),
    {
      totalShownSS: 0,
      shelfShownSS: 0,
      shelfClickSS: 0,
      shelfRevenue: 0,
      totalRevenue: 0,
    },
  );

  const metrics: MetricDefinition[] = [
    {
      label: '全体セッション数',
      value: totals.totalShownSS.toLocaleString(),
      color: '#9C27B0',
      icon: <Users size={24} />,
      description: 'サイトを訪れたユーザーの総セッション数',
      calculation: '期間中の<strong>全ユーザーセッション数</strong>の合計値',
      insights:
        'サイトの<strong>集客力</strong>を示す最重要指標です。サイトの<strong>認知度</strong>と<strong>集客施策</strong>の効果を反映します。',
      interpretation: {
        high: '多くのユーザーがサイトに興味を持っている状態',
        low: '集客施策の見直しや認知度向上が必要な状態',
      },
    },
    {
      label: 'シェルフ閲覧数',
      value: totals.shelfShownSS.toLocaleString(),
      color: '#4CAF50',
      icon: <Eye size={24} />,
      description: 'シェルフが表示されたセッション数',
      calculation:
        '期間中の<strong>シェルフ表示セッション数</strong>の合計。\n<strong>シェルフ露出率</strong> = シェルフ閲覧数 ÷ 全体セッション数',
      insights:
        'シェルフの<strong>リーチ</strong>を示す指標です。全体セッション数に対する比率で<strong>表示機会の最大化</strong>を評価できます。',
      interpretation: {
        high: 'シェルフの露出機会が十分に確保できている状態',
        low: 'シェルフ表示位置の改善が必要な状態',
      },
    },
    {
      label: 'シェルフ経由セッション',
      value: totals.shelfClickSS.toLocaleString(),
      color: '#FF9800',
      icon: <MousePointerClick size={24} />,
      description: 'シェルフ商品をクリックしたセッション数',
      calculation:
        '期間中の<strong>シェルフクリックセッション数</strong>の合計。\n<strong>クリック率（CTR）</strong> = シェルフ経由セッション ÷ シェルフ閲覧数',
      insights:
        'シェルフの<strong>魅力度</strong>と<strong>精度</strong>を示す指標です。ユーザーの<strong>興味関心</strong>と<strong>ニーズ</strong>にマッチした商品を提案できているかを評価できます。',
      interpretation: {
        high: 'シェルフが効果的にユーザーの興味を引いている状態',
        low: 'シェルフ内容の改善が必要な状態',
      },
    },
    {
      label: 'シェルフ経由売上',
      value: `¥${(totals.shelfRevenue / 1000000).toLocaleString()}M`,
      color: '#F44336',
      icon: <DollarSign size={24} />,
      description: 'シェルフ経由での購入による売上金額',
      calculation:
        '<strong>シェルフ経由CVs × 単価</strong>による売上金額の合計。\n<strong>購入率（CVR）</strong> = 購入数 ÷ シェルフ経由セッション',
      insights:
        'シェルフの<strong>直接的な収益貢献</strong>を示す指標です。提案商品の<strong>購買意欲喚起力</strong>と<strong>価格帯適正</strong>を評価できます。',
      interpretation: {
        high: 'シェルフが効果的に売上に貢献している状態',
        low: 'シェルフ商品の選定基準や価格帯の見直しが必要な状態',
      },
    },
    {
      label: '全体売上',
      value: `¥${(totals.totalRevenue / 1000000).toLocaleString()}M`,
      color: '#2196F3',
      icon: <LineChart size={24} />,
      description: 'サイト全体での総売上金額',
      calculation:
        '期間中の<strong>全CVs × 単価</strong>による売上金額の合計。\n<strong>シェルフ貢献率</strong> = シェルフ経由売上 ÷ 全体売上',
      insights:
        '事業の<strong>全体的な収益規模</strong>を示す指標です。シェルフ経由売上との比較で、レコメンドの<strong>売上貢献度</strong>と<strong>重要性</strong>が分かります。',
      interpretation: {
        high: 'ビジネスが好調に推移している状態',
        low: '全社的な施策検討と改善が必要な状態',
      },
    },
  ];

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gap: 3,
        mb: 4,
      }}
    >
      {metrics.map((metric) => (
        <Box
          key={metric.label}
          position="relative"
          onMouseEnter={() => setHoveredCard(metric.label)}
          onMouseLeave={() => setHoveredCard(null)}
        >
          <Card
            sx={{
              p: 3,
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider',
              transition: 'all 0.3s ease',
              transform:
                hoveredCard === metric.label ? 'translateY(-4px)' : 'none',
              boxShadow:
                hoveredCard === metric.label
                  ? '0 8px 24px rgba(0, 0, 0, 0.12)'
                  : '0 4px 12px rgba(0, 0, 0, 0.08)',
              height: '100%',
              position: 'relative',
              zIndex: hoveredCard === metric.label ? 2 : 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <Box sx={{ color: metric.color }}>{metric.icon}</Box>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  fontWeight: 'medium',
                  fontSize: '1.0rem',
                }}
              >
                {metric.label}
              </Typography>
            </Box>
            <Typography
              variant="h4"
              component="div"
              sx={{
                fontWeight: 'bold',
                color: metric.color,
                fontSize: '2.0rem',
              }}
            >
              {metric.value}
            </Typography>
          </Card>

          {hoveredCard === metric.label && (
            <Card
              sx={{
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                mt: 1,
                p: 2,
                backgroundColor: 'white',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                borderRadius: 2,
                zIndex: 3,
                border: `2px solid ${metric.color}`,
                width: '300px',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  color: metric.color,
                  fontWeight: 'bold',
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {metric.icon} {metric.label}とは？
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {metric.description}
              </Typography>

              <InfoBox
                title="計算方法"
                icon={<Calculator size={18} />}
                type="text"
              >
                {metric.calculation}
              </InfoBox>

              <InfoBox
                title="ビジネスインサイト"
                icon={<Lightbulb size={18} />}
                type="text"
              >
                {metric.insights}
              </InfoBox>

              <InfoBox
                title="指標の解釈"
                icon={<HelpCircle size={18} />}
                type="interpretation"
                interpretation={metric.interpretation}
              />
            </Card>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default MetricsSummaryCards;
