import api from './api';

export interface ShelfMetrics {
  url_page_type: string;
  shelf_type: string;
  gray_tagline: string;
  bold_tagline: string;
  shown: number;
  scroll: number;
  click: number;
  cv: number;
  cart: number;
  favorite: number;
  scrollr: number;
  clickr: number;
  cvr: number;
  cartr: number;
  favoriter: number;
  revenue: number;
}

export interface DailyShelfMetrics {
  date: string;
  url_page_type: string;
  shown: number;
  click: number;
  cv: number;
  revenue: number;
}

export interface DailySSMetrics {
  ymd: string;
  ss: number;
  pdp: number;
  shelf_click: number;
  shelf_shown: number;
  cv: number;
  shelf_pdp: number;
  shelf_cv: number;
  revenue: number;
  shelf_revenue: number;
}

export interface ShelfStats {
  stats: ShelfMetrics[];
}

export interface DailyMetricsResponse {
  metrics: DailyShelfMetrics[];
}

export interface DailySSMetricsResponse {
  metrics: DailySSMetrics[];
}

export interface GetShelfStatsRequest {
  start_date: string;
  end_date: string;
}

export const shelfService = {
  async getShelfStats(params: GetShelfStatsRequest): Promise<ShelfStats> {
    const response = await api.post('/shelf/stats', params);
    return response.data;
  },

  async getDailyMetrics(
    params: GetShelfStatsRequest,
  ): Promise<DailyMetricsResponse> {
    const response = await api.post('/shelf/daily_metrics', params);
    return response.data;
  },

  async getDailySSMetrics(
    params: GetShelfStatsRequest,
  ): Promise<DailySSMetricsResponse> {
    const response = await api.post('/shelf/daily_ss_metrics', params);
    return response.data;
  },
};
