import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import {
  Archive,
  BarChart2,
  ChevronDown,
  ChevronRight,
  DollarSign,
  Eye,
  FileText,
  MousePointerClick,
  Percent,
  ShoppingCart,
} from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ComponentMetrics } from '../../../services/component-service';
import {
  getPageTypeDescription,
  getPageTypeLabel,
} from '../../../utils/page-type-utils';
import { MetricTooltip, MetricTooltipProps } from './MetricTooltip';

interface IXSummaryTableProps {
  metrics: ComponentMetrics[];
  startDate: string;
  endDate: string;
  unitPrice: number;
}

interface PageSummary {
  pageType: string;
  componentCount: number;
  shelfCount: number;
  shown: number;
  click: number;
  cv: number;
  ctr: number;
  cvr: number;
  revenue: number;
  shelves?: ComponentMetrics[];
}

interface Column {
  id: keyof PageSummary;
  label: string;
  tooltip: Omit<MetricTooltipProps, 'title'> & {
    title: React.ReactNode;
  };
  format: (value: any) => string | number;
  showBar?: boolean;
  getBarMaxValue?: (summaries: PageSummary[]) => number;
  width?: string;
}

const IXSummaryTable: React.FC<IXSummaryTableProps> = ({
  metrics,
  startDate,
  endDate,
  unitPrice,
}) => {
  const [expandedPage, setExpandedPage] = useState<string | null>(null);
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const columnHeaderRefs = useRef<Record<string, DOMRect>>({});

  const daysDiff = differenceInDays(parseISO(endDate), parseISO(startDate)) + 1;

  const safeNumber = (value: number | null | undefined): number => {
    if (value === null || value === undefined || isNaN(value)) return 0;
    return value;
  };

  const safeDivide = (numerator: number, denominator: number): number => {
    if (denominator === 0 || !denominator) return 0;
    const result = numerator / denominator;
    return isFinite(result) ? result : 0;
  };

  // revenue の計算部分を修正
  const calculateMonthlyRevenue = (cv: number): number => {
    const dailyRevenue = (cv * unitPrice) / daysDiff; // unitPriceを使用
    return Math.ceil(dailyRevenue * 30);
  };

  const summaries: PageSummary[] = Object.entries(
    metrics.reduce((acc: Record<string, ComponentMetrics[]>, metric) => {
      if (metric.component?.startsWith('IX')) {
        const pageType = metric.url_page_type;
        if (!acc[pageType]) acc[pageType] = [];
        acc[pageType].push(metric);
      }
      return acc;
    }, {}),
  )
    .map(([pageType, pageMetrics]) => {
      const shown = pageMetrics.reduce(
        (max, m) => Math.max(max, safeNumber(m.shown)),
        0,
      );
      const click = pageMetrics.reduce(
        (sum, m) => sum + safeNumber(m.click),
        0,
      );
      const cv = pageMetrics.reduce((sum, m) => sum + safeNumber(m.cv), 0);
      const shelfCount = pageMetrics.filter((m) => m.type === 'shelf').length;
      const componentCount = pageMetrics.filter(
        (m) => m.type === 'component',
      ).length;

      const shelves = pageMetrics
        .filter((m) => m.type === 'shelf')
        .map((m) => ({
          ...m,
          ctr: safeDivide(m.click, m.shown),
          cvr: safeDivide(m.cv, m.click),
          revenue: calculateMonthlyRevenue(m.cv),
        }))
        .sort((a, b) => (b.shown || 0) - (a.shown || 0));

      return {
        pageType,
        componentCount,
        shelfCount,
        shown,
        click,
        cv,
        ctr: safeDivide(click, shown),
        cvr: safeDivide(cv, click),
        revenue: calculateMonthlyRevenue(cv),
        shelves,
      };
    })
    .sort((a, b) => b.shown - a.shown);

  const columns: Column[] = [
    {
      id: 'pageType',
      label: 'ページタイプ',
      tooltip: {
        title: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FileText size={18} />
            ページタイプ
          </Box>
        ),
        description: 'コンポーネントが設置されているページの種類',
        calculation:
          'ページタイプは<strong>URLパターン</strong>から自動的に判定されます',
        insights:
          '各ページタイプの<strong>特性</strong>や<strong>ユーザー行動</strong>を理解することで、より効果的なレコメンド戦略を立てることができます',
      },
      format: (value: string) => value,
      width: '250px',
    },
    {
      id: 'shown',
      label: '表示数',
      tooltip: {
        title: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Eye size={18} />
            表示数
          </Box>
        ),
        description: '期間中にユーザーの画面に表示された回数',
        calculation:
          'ページが<strong>表示</strong>され、<strong>シェルフが画面内に入った</strong>回数の合計',
        insights:
          'シェルフの<strong>リーチ</strong>を示す基本指標です。表示数が多いほど、より多くのユーザーにシェルフが表示されていることを意味します',
      },
      format: (value: number) => value.toLocaleString(),
      showBar: true,
      getBarMaxValue: (summaries) => Math.max(...summaries.map((s) => s.shown)),
      width: '120px',
    },
    {
      id: 'shelfCount',
      label: 'シェルフ数',
      tooltip: {
        title: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Archive size={18} />
            シェルフ数
          </Box>
        ),
        description: 'ページタイプ内で設置されているInsightXシェルフの種類数',
        calculation:
          '該当ページタイプに設置された<strong>シェルフコンポーネント</strong>の種類数',
        insights:
          'ページ内での<strong>シェルフ露出機会</strong>を示します。適切な数のシェルフ設置がユーザー体験とコンバージョンの最適化につながります',
      },
      format: (value: number) => value,
      width: '100px',
    },
    {
      id: 'click',
      label: 'クリック数',
      tooltip: {
        title: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <MousePointerClick size={18} />
            クリック数
          </Box>
        ),
        description: 'ユーザーがシェルフ商品をクリックした回数',
        calculation:
          'シェルフ内で商品が<strong>クリック</strong>された回数の合計',
        insights:
          'ユーザーの<strong>興味関心</strong>の度合いを示す重要指標です。シェルフの精度や表示の適切さを評価する基準となります',
      },
      format: (value: number) => value.toLocaleString(),
      showBar: true,
      getBarMaxValue: (summaries) => Math.max(...summaries.map((s) => s.click)),
      width: '120px',
    },
    {
      id: 'ctr',
      label: 'クリック率',
      tooltip: {
        title: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <BarChart2 size={18} />
            クリック率（CTR）
          </Box>
        ),
        description: '表示数に対するクリック数の割合',
        calculation: '<strong>クリック率</strong> = クリック数 ÷ 表示数',
        insights:
          'シェルフの<strong>魅力度</strong>と<strong>関連性</strong>を示す指標です。高いCTRは、ユーザーのニーズに合った商品を提案できていることを示唆します',
      },
      format: (value: number) => `${(value * 100).toFixed(1)}%`,
      showBar: true,
      getBarMaxValue: (summaries) =>
        Math.max(...summaries.map((s) => s.ctr)) * 1.25,
      width: '100px',
    },
    {
      id: 'cv',
      label: 'CV数',
      tooltip: {
        title: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ShoppingCart size={18} />
            コンバージョン数（CV数）
          </Box>
        ),
        description: 'シェルフ経由での商品購入回数',
        calculation: 'シェルフ商品が<strong>購入</strong>された回数の合計',
        insights:
          'シェルフの<strong>最終的な成果</strong>を示す指標です。売上に直結する重要なKPIとして、レコメンドの実質的な効果を評価します',
      },
      format: (value: number) => value.toLocaleString(),
      showBar: true,
      getBarMaxValue: (summaries) => Math.max(...summaries.map((s) => s.cv)),
      width: '100px',
    },
    {
      id: 'cvr',
      label: 'CVR',
      tooltip: {
        title: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Percent size={18} />
            コンバージョン率（CVR）
          </Box>
        ),
        description: 'クリック数に対する購入数の割合',
        calculation: '<strong>コンバージョン率</strong> = CV数 ÷ クリック数',
        insights:
          'シェルフクリックから購買までの<strong>転換効率</strong>を示す指標です。商品の<strong>訴求力</strong>や<strong>購買意欲喚起</strong>の成功度を評価できます',
      },
      format: (value: number) => `${(value * 100).toFixed(1)}%`,
      showBar: true,
      getBarMaxValue: (summaries) =>
        Math.max(...summaries.map((s) => s.cvr)) * 1.25,
      width: '100px',
    },
    {
      id: 'revenue',
      label: '月間換算売上',
      tooltip: {
        title: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DollarSign size={18} />
            月間換算売上
          </Box>
        ),
        description: '期間中の売上を1ヶ月に換算した金額',
        calculation:
          '1ヶ月あたりの想定売上 = <strong>(CV数 × 10,000円 × 30日) ÷ 集計期間日数</strong>',
        insights:
          'シェルフの<strong>収益貢献度</strong>を示す指標です。ビジネスインパクトを金額で評価することで、投資対効果（ROI）の判断材料となります',
      },
      format: (value: number) => `¥${value.toLocaleString()}`,
      showBar: true,
      getBarMaxValue: (summaries) =>
        Math.max(...summaries.map((s) => s.revenue)),
      width: '150px',
    },
  ];

  const updateColumnHeaderPosition = useCallback(
    (columnId: string, element: HTMLTableCellElement) => {
      const rect = element.getBoundingClientRect();
      columnHeaderRefs.current[columnId] = rect;
    },
    [],
  );

  const handleColumnHover = useCallback((columnId: string) => {
    const rect = columnHeaderRefs.current[columnId];
    if (rect) {
      setHoveredColumn(columnId);
      setTooltipPosition({
        top: rect.top,
        left: rect.left + rect.width / 2,
      });
    }
  }, []);

  const handleColumnLeave = useCallback(() => {
    setHoveredColumn(null);
    setTooltipPosition(null);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      columns.forEach((column) => {
        const element = document.querySelector(
          `[data-column-id="${column.id}"]`,
        ) as HTMLTableCellElement;
        if (element) {
          updateColumnHeaderPosition(column.id, element);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [updateColumnHeaderPosition]);

  const getColumnHighlightStyles = (
    columnId: string,
    isShelfRow: boolean = false,
  ) => {
    const isHovered = hoveredColumn === columnId;
    return {
      '& .metrics-bar': {
        opacity: isHovered ? 0.3 : 0.1,
      },
      '& .metrics-value': {
        color: isHovered ? 'primary.main' : 'inherit',
        fontWeight: isHovered ? 'bold' : 'normal',
      },
      bgcolor: isHovered
        ? isShelfRow
          ? 'action.hover'
          : 'background.paper'
        : isShelfRow
          ? 'grey.50'
          : 'background.paper',
    };
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 2,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                data-column-id={column.id}
                ref={(el: HTMLTableCellElement | null) =>
                  el && updateColumnHeaderPosition(column.id, el)
                }
                align={column.id === 'pageType' ? 'left' : 'right'}
                sx={{
                  whiteSpace: 'nowrap',
                  bgcolor: 'background.paper',
                  borderBottom: '2px solid',
                  borderColor: 'divider',
                  position: column.id === 'pageType' ? 'sticky' : 'static',
                  left: column.id === 'pageType' ? 0 : 'auto',
                  zIndex: column.id === 'pageType' ? 2 : 1,
                  width: column.width,
                  minWidth: column.width,
                  maxWidth: column.width,
                  boxShadow:
                    column.id === 'pageType'
                      ? '4px 0 8px -4px rgba(0,0,0,0.1)'
                      : 'none',
                }}
              >
                <Box
                  onMouseEnter={() => handleColumnHover(column.id)}
                  onMouseLeave={handleColumnLeave}
                  sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent:
                        column.id === 'pageType' ? 'flex-start' : 'flex-end',
                      gap: 0.5,
                      position: 'relative',
                      zIndex: 2,
                    }}
                  >
                    {(() => {
                      const icon = {
                        pageType: <FileText size={16} />,
                        shown: <Eye size={16} />,
                        shelfCount: <Archive size={16} />,
                        click: <MousePointerClick size={16} />,
                        ctr: <BarChart2 size={16} />,
                        cv: <ShoppingCart size={16} />,
                        cvr: <Percent size={16} />,
                        revenue: <DollarSign size={16} />,
                      }[column.id];
                      return (
                        <>
                          {icon}
                          {column.label}
                        </>
                      );
                    })()}
                  </Box>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {summaries.map((summary) => (
            <React.Fragment key={summary.pageType}>
              <TableRow
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
                onClick={() =>
                  setExpandedPage(
                    expandedPage === summary.pageType ? null : summary.pageType,
                  )
                }
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.id === 'pageType' ? 'left' : 'right'}
                    onMouseEnter={() => handleColumnHover(column.id)}
                    onMouseLeave={handleColumnLeave}
                    sx={{
                      position:
                        column.id === 'pageType' ? 'sticky' : 'relative',
                      left: column.id === 'pageType' ? 0 : 'auto',
                      bgcolor:
                        column.id === 'pageType'
                          ? 'primary.lighter'
                          : 'background.paper',
                      zIndex: column.id === 'pageType' ? 1 : 0,
                      py: 2,
                      width: column.width,
                      minWidth: column.width,
                      maxWidth: column.width,
                      borderRight:
                        column.id === 'pageType' ? '1px solid' : 'none',
                      borderRightColor: 'divider',
                      boxShadow:
                        column.id === 'pageType'
                          ? '4px 0 8px -4px rgba(0,0,0,0.1)'
                          : 'none',
                      cursor: 'pointer',
                      ...getColumnHighlightStyles(column.id),
                    }}
                  >
                    {column.id === 'pageType' ? (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <IconButton size="small">
                          {expandedPage === summary.pageType ? (
                            <ChevronDown size={20} />
                          ) : (
                            <ChevronRight size={20} />
                          )}
                        </IconButton>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: 'bold', color: 'primary.main' }}
                          >
                            {getPageTypeLabel(summary.pageType)}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ color: 'text.secondary', display: 'block' }}
                          >
                            {getPageTypeDescription(summary.pageType)}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        {column.showBar && (
                          <Box
                            className="metrics-bar"
                            sx={{
                              position: 'absolute',
                              left: 0,
                              top: 0,
                              bottom: 0,
                              width: `${
                                ((summary[column.id] as number) /
                                  (column.getBarMaxValue?.(summaries) || 1)) *
                                100
                              }%`,
                              bgcolor: 'primary.main',
                              transition: 'opacity 0.2s',
                            }}
                          />
                        )}
                        <Typography
                          variant="body2"
                          className="metrics-value"
                          sx={{ position: 'relative' }}
                        >
                          {column.format(summary[column.id])}
                        </Typography>
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{ p: 0, bgcolor: 'grey.50' }}
                >
                  <Collapse in={expandedPage === summary.pageType}>
                    <Box>
                      <Table size="small">
                        <TableBody>
                          {summary.shelves?.map((shelf) => (
                            <TableRow
                              key={shelf.component}
                              sx={{
                                '&:hover': { bgcolor: 'action.hover' },
                              }}
                            >
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={
                                    column.id === 'pageType' ? 'left' : 'right'
                                  }
                                  onMouseEnter={() =>
                                    handleColumnHover(column.id)
                                  }
                                  onMouseLeave={handleColumnLeave}
                                  sx={{
                                    position:
                                      column.id === 'pageType'
                                        ? 'sticky'
                                        : 'relative',
                                    left: column.id === 'pageType' ? 0 : 'auto',
                                    zIndex: column.id === 'pageType' ? 1 : 0,
                                    pl: column.id === 'pageType' ? 8 : 2,
                                    width: column.width,
                                    minWidth: column.width,
                                    maxWidth: column.width,
                                    borderRight:
                                      column.id === 'pageType'
                                        ? '1px solid'
                                        : 'none',
                                    borderRightColor: 'divider',
                                    cursor: 'pointer',
                                    ...getColumnHighlightStyles(
                                      column.id,
                                      true,
                                    ),
                                  }}
                                >
                                  {column.id === 'pageType' ? (
                                    <Typography variant="body2">
                                      {shelf.component}
                                    </Typography>
                                  ) : (
                                    <>
                                      {column.showBar && (
                                        <Box
                                          className="metrics-bar"
                                          sx={{
                                            position: 'absolute',
                                            left: 0,
                                            top: 0,
                                            bottom: 0,
                                            width: `${
                                              ((shelf[
                                                column.id as keyof ComponentMetrics
                                              ] as number) /
                                                (column.getBarMaxValue?.(
                                                  summaries,
                                                ) || 1)) *
                                              100
                                            }%`,
                                            bgcolor: 'primary.main',
                                            transition: 'opacity 0.2s',
                                          }}
                                        />
                                      )}
                                      <Typography
                                        variant="body2"
                                        className="metrics-value"
                                        sx={{ position: 'relative' }}
                                      >
                                        {column.format(
                                          shelf[
                                            column.id as keyof ComponentMetrics
                                          ],
                                        )}
                                      </Typography>
                                    </>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      {hoveredColumn && tooltipPosition && (
        <Box
          sx={{
            position: 'fixed',
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            zIndex: 9999,
            transform: 'translate(-50%, -100%)',
          }}
        >
          {(() => {
            const column = columns.find((col) => col.id === hoveredColumn);
            return column?.tooltip ? (
              <MetricTooltip {...column.tooltip} />
            ) : null;
          })()}
        </Box>
      )}
    </TableContainer>
  );
};

export default IXSummaryTable;
