import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { ChevronDown, ChevronRight } from 'lucide-react';
import React from 'react';
import { columns } from './constants';
import { MetricsRow } from './MetricsRow';
import { ShelfTypeGroupProps } from './types';
import {
  calculateMonthlyRevenue,
  calculateSummaryMetrics,
  safeNumber,
} from './utils';

const STICKY_COLUMN_STYLE = {
  position: 'sticky',
  left: 0,
  zIndex: 2,
  bgcolor: 'background.paper',
  borderRight: '1px solid',
  borderRightColor: 'divider',
  minWidth: '300px',
  width: '300px',
} as const;

export const ShelfTypeGroup: React.FC<ShelfTypeGroupProps> = ({
  shelfTypeKey,
  metrics,
  maxValues,
  isExpanded,
  onExpand,
  startDate,
  endDate,
  unitPrice,
  hoveredColumn,
  onColumnHover,
  onColumnLeave,
}) => {
  const summaryMetrics = calculateSummaryMetrics(
    metrics,
    unitPrice,
    startDate,
    endDate,
  );

  const getColumnStyle = (columnId: string) => ({
    position: 'relative',
    textAlign: 'right' as const,
    py: 1.5,
    px: 2,
    whiteSpace: 'nowrap' as const,
    width: columns.find((col) => col.id === columnId)?.width,
    minWidth: columns.find((col) => col.id === columnId)?.width,
    cursor: 'pointer',
    '&:hover': {
      '& .metrics-bar': {
        opacity: 0.15,
      },
      '& .metrics-value': {
        color: 'primary.main',
      },
    },
  });

  return (
    <React.Fragment>
      <TableRow
        sx={{
          cursor: 'pointer',
          '&:hover': { bgcolor: 'action.hover' },
        }}
      >
        <TableCell
          sx={STICKY_COLUMN_STYLE}
          onClick={() => onExpand(shelfTypeKey)}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <IconButton size="small">
              {isExpanded ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronRight size={20} />
              )}
            </IconButton>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {metrics[0].shelf_type} ({metrics.length}種類)
            </Typography>
          </Box>
        </TableCell>
        {columns.map((column) => {
          const value =
            column.id === 'revenue'
              ? calculateMonthlyRevenue(
                  safeNumber(summaryMetrics.cv),
                  unitPrice,
                  startDate,
                  endDate,
                )
              : safeNumber(summaryMetrics[column.id]);

          const percentage =
            maxValues[column.id] > 0 ? (value / maxValues[column.id]) * 100 : 0;
          const limitedPercentage = Math.min(percentage, 99);

          return (
            <TableCell
              key={column.id}
              sx={{
                ...getColumnStyle(column.id),
                '& .metrics-value': {
                  color:
                    hoveredColumn === column.id
                      ? 'primary.main'
                      : 'text.primary',
                  fontWeight: hoveredColumn === column.id ? 'bold' : 'medium',
                },
                '& .metrics-bar': {
                  opacity: hoveredColumn === column.id ? 0.3 : 0.1,
                },
              }}
              onMouseEnter={() => onColumnHover(column.id)}
              onMouseLeave={onColumnLeave}
            >
              <Box
                className="metrics-bar"
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  width: `${limitedPercentage}%`,
                  bgcolor: 'primary.main',
                  transition: 'opacity 0.2s',
                }}
              />
              <Typography
                variant="body2"
                className="metrics-value"
                sx={{
                  position: 'relative',
                  transition: 'color 0.2s, font-weight 0.2s',
                }}
              >
                {column.format(value)}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
      {isExpanded && (
        <>
          {metrics.map((metric) => (
            <MetricsRow
              key={`${metric.shelf_type}-${metric.gray_tagline}-${metric.bold_tagline}`}
              metric={metric}
              maxValues={maxValues}
              startDate={startDate}
              endDate={endDate}
              unitPrice={unitPrice}
              hoveredColumn={hoveredColumn}
              onColumnHover={onColumnHover}
              onColumnLeave={onColumnLeave}
            />
          ))}
        </>
      )}
    </React.Fragment>
  );
};
