import { Box, Card, Typography } from '@mui/material';
import { Calculator, Lightbulb } from 'lucide-react';
import React from 'react';

export interface MetricTooltipProps {
  title: React.ReactNode;
  description: string;
  calculation: string;
  insights: string;
}

export const MetricTooltip: React.FC<MetricTooltipProps> = ({
  title,
  description,
  calculation,
  insights,
}) => (
  <Card
    sx={{
      width: 300,
      p: 2,
      bgcolor: 'background.paper',
      boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
      position: 'relative',
      zIndex: 9999,
    }}
  >
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: 'bold',
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        color: 'primary.main',
      }}
    >
      {title}
    </Typography>
    <Typography variant="body2" sx={{ mb: 2 }}>
      {description}
    </Typography>

    <Box sx={{ mb: 2 }}>
      <Typography
        variant="subtitle2"
        sx={{
          color: 'text.secondary',
          fontWeight: 'bold',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Calculator size={18} /> 計算方法
      </Typography>
      <Typography
        variant="body2"
        sx={{
          pl: 3,
          '& strong': {
            fontWeight: 'bold',
            color: 'text.primary',
          },
        }}
        dangerouslySetInnerHTML={{ __html: calculation }}
      />
    </Box>

    <Box sx={{ mb: 2 }}>
      <Typography
        variant="subtitle2"
        sx={{
          color: 'text.secondary',
          fontWeight: 'bold',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Lightbulb size={18} /> ビジネスインサイト
      </Typography>
      <Typography
        variant="body2"
        sx={{
          pl: 3,
          '& strong': {
            fontWeight: 'bold',
            color: 'text.primary',
          },
        }}
        dangerouslySetInnerHTML={{ __html: insights }}
      />
    </Box>
  </Card>
);

export default MetricTooltip;
