import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { componentService } from '../../../services/component-service';
import { shelfService } from '../../../services/shelf-service';
import { useClient } from '../../../store/client.store';
import ComparisonSection from './ComparisonSection';
import IXSummarySection from './IXSummarySection';
import MetricsHeader from './MetricsHeader';
import ShelfPerformanceSection from './ShelfPerformanceSection';

const getDefaultUnitPrice = (client: string | null): number => {
  if (!client) return 10000;

  const clientName = client.toLowerCase();
  switch (clientName) {
    case 'can-1c558cb2-089b-4176-ae80-0df05def6848':
      return 9700;
    case 'pal-7e490282-fe4b-4260-b635-cb387b175f27':
      return 8700;
    case 'onward-a1737baa-85fc-458b-9951-0205f9c26311':
      return 15000;
    default:
      return 10000;
  }
};

const MetricsViewer: React.FC = () => {
  const { client: clientState } = useClient();
  const [startDate, setStartDate] = useState(
    format(new Date().setDate(new Date().getDate() - 31), 'yyyy-MM-dd'),
  );
  const [endDate, setEndDate] = useState(
    format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd'),
  );
  const [selectedPageType, setSelectedPageType] = useState('');
  const [selectedShelfPageType, setSelectedShelfPageType] = useState('all');
  const [unitPrice, setUnitPrice] = useState<number>(() => {
    const storageKey = `unitPrice_${clientState.selectedClient?.clientId ?? 'default'}`;
    const savedPrice = localStorage.getItem(storageKey);
    return savedPrice
      ? parseInt(savedPrice)
      : getDefaultUnitPrice(clientState.selectedClient?.clientId);
  });

  useEffect(() => {
    const storageKey = `unitPrice_${clientState.selectedClient?.clientId ?? 'default'}`;
    const savedPrice = localStorage.getItem(storageKey);
    if (!savedPrice) {
      const defaultPrice = getDefaultUnitPrice(
        clientState.selectedClient?.clientId,
      );
      setUnitPrice(defaultPrice);
      localStorage.setItem(storageKey, defaultPrice.toString());
    } else {
      setUnitPrice(parseInt(savedPrice));
    }
  }, [clientState.selectedClient]);

  const handleUnitPriceChange = (newPrice: number) => {
    setUnitPrice(newPrice);
    const storageKey = `unitPrice_${clientState.selectedClient?.clientId ?? 'default'}`;
    localStorage.setItem(storageKey, newPrice.toString());
  };

  const {
    data: componentData,
    isLoading: isLoadingComponent,
    error: componentError,
  } = useQuery({
    queryKey: [
      'componentStats',
      startDate,
      endDate,
      clientState.selectedClient,
    ],
    queryFn: () =>
      componentService.getComponentStats({
        start_date: startDate,
        end_date: endDate,
      }),
  });

  const {
    data: dailyMetricsData,
    isLoading: isLoadingDailyMetrics,
    error: dailyMetricsError,
  } = useQuery({
    queryKey: ['dailyMetrics', startDate, endDate, clientState.selectedClient],
    queryFn: () =>
      shelfService.getDailyMetrics({
        start_date: startDate,
        end_date: endDate,
      }),
  });

  const {
    data: dailySSMetricsData,
    isLoading: isLoadingDailySSMetrics,
    error: dailySSMetricsError,
  } = useQuery({
    queryKey: [
      'dailySSMetrics',
      startDate,
      endDate,
      clientState.selectedClient,
    ],
    queryFn: () =>
      shelfService.getDailySSMetrics({
        start_date: startDate,
        end_date: endDate,
      }),
  });

  const handlePageTypeChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setSelectedPageType(newValue);
  };

  if (isLoadingComponent || isLoadingDailyMetrics || isLoadingDailySSMetrics) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (componentError || dailyMetricsError || dailySSMetricsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <Typography color="error">
          データの読み込み中にエラーが発生しました
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ pt: 3, pb: 4 }}>
      <Box mb={4}>
        <MetricsHeader
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          unitPrice={unitPrice}
          onUnitPriceChange={handleUnitPriceChange}
        />
      </Box>

      <IXSummarySection
        metrics={componentData.stats}
        dailyMetrics={dailyMetricsData.metrics}
        dailySSMetrics={dailySSMetricsData.metrics}
        startDate={startDate}
        endDate={endDate}
        unitPrice={unitPrice}
      />

      <ComparisonSection
        metrics={componentData.stats}
        selectedPageType={selectedPageType}
        onPageTypeChange={handlePageTypeChange}
        startDate={startDate}
        endDate={endDate}
        unitPrice={unitPrice}
      />

      <ShelfPerformanceSection
        startDate={startDate}
        endDate={endDate}
        selectedPageType={selectedShelfPageType}
        onPageTypeChange={setSelectedShelfPageType}
        unitPrice={unitPrice}
      />
    </Container>
  );
};

export default MetricsViewer;
